// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { BASE_URL } from '../../../config';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  product: getIcon('ic_phone'),
  food: getIcon('ic_food'),
  kitchen: getIcon('ic_kitchen'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  stock: getIcon('ic_stock'),
  sale: getIcon('ic_sale'),
  hr: getIcon('ic_hr'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  finance: getIcon('ic_finance'),
  analytics: getIcon('ic_analytics'),
  settings: getIcon('ic_cog'),
  supplier: getIcon('ic-supplier'),
  purchase: getIcon('ic_purchase'),
  productReceiving: getIcon('receiver'),
  receiveHand: getIcon('receive-hand'),
  assets: getIcon('ic-assets'),
};

const navConfig = (permissionsList) =>
  [
    {
      subheader: 'Dashboard',
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.dashboard.root,
          icon: ICONS.dashboard,
          children: [
            {
              title: 'Analytics',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Social',
      items: [
        {
          title: 'Social',
          path: PATH_DASHBOARD.social.root,
          icon: ICONS.dashboard,
          children: [
            {
              title: 'Meta',
              path: PATH_DASHBOARD.social.meta.root,
              children: [
                {
                  title: 'Chat',
                  path: PATH_DASHBOARD.social.meta.chat,
                  code: 'FOOD_CATEGORY_PAGE',
                },
                {
                  title: 'FB Connect',
                  path: PATH_DASHBOARD.social.meta.MetaConnect,
                  code: 'FOOD_CATEGORY_PAGE',
                },
              ],
            },
            {
              title: 'Call Center',
              path: PATH_DASHBOARD.social.callCenter.root,
              children: [
                {
                  title: 'Manage',
                  path: PATH_DASHBOARD.social.callCenter.manage,
                  code: 'FOOD_CATEGORY_PAGE',
                },
                {
                  title: 'Logs',
                  path: PATH_DASHBOARD.social.callCenter.logs,
                  code: 'FOOD_CATEGORY_PAGE',
                },
                {
                  title: 'Abandon Calls',
                  path: PATH_DASHBOARD.social.callCenter.abandonCalls,
                  code: 'FOOD_CATEGORY_PAGE',
                },
              ],
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Products',
      items: [
        {
          title: 'Products',
          path: PATH_DASHBOARD.product.root,
          icon: ICONS.product,
          children: [
            {
              title: 'View List',
              path: PATH_DASHBOARD.product.list,
              code: 'FOOD_CATEGORY_PAGE',
            },
            {
              title: 'Variations',
              path: PATH_DASHBOARD.product.variations,
              code: 'FOOD_CATEGORY_PAGE',
            },
            {
              title: 'Tags',
              path: PATH_DASHBOARD.product.tags,
              code: 'PRODUCT_TAGS',
            },
            {
              title: 'Attributes',
              path: PATH_DASHBOARD.product.attributes,
              code: 'PRODUCT_TAGS',
            },
            {
              title: 'Categories',
              path: PATH_DASHBOARD.product.category,
              code: 'FOOD_CATEGORY_PAGE',
            },
          ],
        },
      ],
      code: 'FOOD_PAGE',
      production: true,
    },
    {
      subheader: 'Suppliers',
      items: [
        {
          title: 'Suppliers',
          path: PATH_DASHBOARD.suppliers.root,
          icon: ICONS.supplier,
          children: [
            {
              title: 'Stock Suppliers',
              path: PATH_DASHBOARD.suppliers.stock,
              code: 'STOCK_SUPPLIER_PAGE',
            },
            {
              title: 'Assets/Services Suppliers',
              path: PATH_DASHBOARD.suppliers.assetsAndServices,
              code: 'ASSET_SERVICE_SUPPLIER_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Purchasing',
      items: [
        {
          title: 'Purchasing',
          path: PATH_DASHBOARD.purchasing.root,
          icon: ICONS.purchase,
          children: [
            {
              title: 'Call & Get Prices',
              path: PATH_DASHBOARD.purchasing.priceChecker,
              code: 'CALL_AND_GET_PRICES_PAGE',
            },
            {
              title: 'Add to Cart',
              path: PATH_DASHBOARD.purchasing.addToCart,
              code: 'ADD_TO_CART_PAGE',
            },
            {
              title: 'Purchase Notes',
              path: PATH_DASHBOARD.purchasing.purchaseNotes,
              code: 'PURCHASE_NOTE_PAGE',
            },
            {
              title: 'Goods Receiving',
              path: PATH_DASHBOARD.purchasing.receiving,
              code: 'GOODS_RECEIVING_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'stock',
      items: [
        {
          title: 'Stock',
          path: PATH_DASHBOARD.stocks.root,
          icon: ICONS.stock,
          children: [
            {
              title: 'View Stock',
              path: PATH_DASHBOARD.stocks.viewAll,
              code: 'VIEW_STOCK_PAGE',
            },
            {
              title: 'View Sold Stock',
              path: PATH_DASHBOARD.stocks.viewPOS,
              code: 'VIEW_SOLD_STOCK',
            },
            {
              title: 'Used Stock',
              path: PATH_DASHBOARD.stocks.usedOnly,
              code: 'VIEW_SOLD_STOCK',
            },
            {
              title: 'Waste Stock',
              path: PATH_DASHBOARD.stocks.wasteOnly,
              code: 'VIEW_SOLD_STOCK',
            },
            {
              title: 'Stock Transfer',
              path: PATH_DASHBOARD.stocks.transfer.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'View Transfer',
                  path: PATH_DASHBOARD.stocks.transfer.view,
                  code: 'STOCK_TRANSFER_PAGE',
                },
                {
                  title: 'Sender',
                  path: PATH_DASHBOARD.stocks.transfer.sender,
                  code: 'STOCK_SENDER_PAGE',
                },
              ],
            },
            {
              title: 'Stock Orders',
              path: PATH_DASHBOARD.stocks.stockOrder.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'Stock Order List',
                  path: PATH_DASHBOARD.stocks.stockOrder.view,
                  code: 'STOCK_ORDER_LIST_PAGE',
                },
                {
                  title: 'Create Stock Order',
                  path: PATH_DASHBOARD.stocks.stockOrder.create,
                  code: 'CREATE_STOCK_ORDER_LIST_PAGE',
                },
              ],
            },
            {
              title: 'Availability Checker',
              path: PATH_DASHBOARD.stocks.availabilityChecker.checker,
              code: 'AVAILABILITY_CHECKER_PAGE',
            },
            {
              title: 'Stock Breaker',
              path: PATH_DASHBOARD.stocks.breaker,
              code: 'STOCK_BREAKER_PAGE',
            },
            {
              title: 'Waste Manager',
              path: PATH_DASHBOARD.stocks.wasteManager,
              code: 'STOCK_BREAKER_PAGE',
            },
            {
              title: 'Used Stock Manager',
              path: PATH_DASHBOARD.stocks.usedStockManager,
              code: 'STOCK_BREAKER_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Sales',
      items: [
        {
          title: 'Sales',
          path: PATH_DASHBOARD.sales.root,
          icon: ICONS.sale,
          children: [
            {
              title: 'Sales List',
              path: PATH_DASHBOARD.sales.saleList,
              code: 'SALES_PAGE',
            },
            {
              title: 'Customer List',
              path: PATH_DASHBOARD.sales.customerList,
              code: 'SALES_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Kitchen',
      items: [
        {
          title: 'Kitchen',
          path: PATH_DASHBOARD.kitchen.root,
          icon: ICONS.kitchen,
          children: [
            {
              title: 'Set Cooking Status',
              path: PATH_DASHBOARD.kitchen.cookingStatus,
              code: 'COOKING_STATUS_PAGE',
            },
            {
              title: 'Cooking Process',
              path: PATH_DASHBOARD.kitchen.cookingProcess,
              code: 'COOKING_PROCESS_PAGE',
            },
            {
              title: 'Set Store Status',
              path: PATH_DASHBOARD.kitchen.store,
              code: 'STORE_STATUS_PAGE',
            },
            {
              title: 'Outlet Kitchen Display',
              path: PATH_DASHBOARD.kitchen.display,
              code: 'KITCHEN_DISPLAY_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Marketing',
      items: [
        {
          title: 'Marketing',
          path: PATH_DASHBOARD.marketing.root,
          icon: ICONS.kitchen,
          children: [
            {
              title: 'Discounts Manager',
              path: PATH_DASHBOARD.marketing.discounts,
              code: 'COOKING_STATUS_PAGE',
            },
            {
              title: 'Vouchers manager',
              path: PATH_DASHBOARD.marketing.vouchers,
              code: 'COOKING_PROCESS_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Human Resource (HR)',
      items: [
        {
          title: 'Human Resource (HR)',
          path: PATH_DASHBOARD.hr.root,
          icon: ICONS.hr,
          children: [
            {
              title: 'Employees',
              path: PATH_DASHBOARD.hr.employees.root,
              children: [
                {
                  title: 'Users List',
                  path: PATH_DASHBOARD.hr.employees.list,
                  code: 'USER_LIST_PAGE',
                },
                {
                  title: 'Former Users List',
                  path: PATH_DASHBOARD.hr.employees.formerList,
                  code: 'USER_LIST_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
            {
              title: 'Salary',
              path: PATH_DASHBOARD.hr.salary.root,
              children: [
                {
                  title: 'Time Log Uploader',
                  path: PATH_DASHBOARD.hr.salary.timeLog,
                  code: 'TIME_LOG_UPLOADER_PAGE',
                },
                {
                  title: 'Salary Generator',
                  path: PATH_DASHBOARD.hr.salary.generateSalary,
                  code: 'SALARY_GENERATOR_PAGE',
                },
              ],
              code: 'SALARY_PAGE',
            },
            {
              title: 'Inspection',
              path: PATH_DASHBOARD.hr.inspection.root,
              children: [
                {
                  title: 'Inspection Type',
                  path: PATH_DASHBOARD.hr.inspection.type,
                  code: 'INSPECTION_TYPE_PAGE',
                },
                {
                  title: 'Inspection Template',
                  path: PATH_DASHBOARD.hr.inspection.templateList,
                  code: 'INSPECTION_TYPE_PAGE',
                },
                {
                  title: 'Inspection Examin',
                  path: PATH_DASHBOARD.hr.inspection.examin,
                  code: 'INSPECTION_EXAMIN_PAGE',
                },
              ],
              code: 'INSPECTION_TYPE_PAGE',
            },
            {
              title: 'Rost Manager',
              path: PATH_DASHBOARD.hr.rost.manager,
              code: 'ROST_MANAGER_PAGE',
            },
            {
              title: 'Leave Manager',
              path: PATH_DASHBOARD.hr.leave.manager,
              code: 'LEAVE_MANAGER_PAGE',
            },
            {
              title: 'HR Reports',
              path: PATH_DASHBOARD.hr.report.root,
              children: [
                {
                  title: 'Salary Reports',
                  path: PATH_DASHBOARD.hr.report.root,
                  code: 'SALARY_REPORTS_PAGE',
                },
              ],
            },
          ],
          code: 'HR_REPORTS_PAGE',
        },
      ],
      production: true,
    },
    {
      subheader: 'Assets',
      items: [
        {
          title: 'Assets',
          path: PATH_DASHBOARD.assets.root,
          icon: ICONS.assets,
          children: [
            {
              title: 'Assets List',
              path: PATH_DASHBOARD.assets.list,
              code: 'ASSETS_LIST_PAGE',
            },
            {
              title: 'Asset Categories',
              path: PATH_DASHBOARD.assets.categories,
              code: 'ASSET_CATEGORIES_PAGE',
            },
          ],
          code: 'ASSETS_PAGE',
        },
      ],
      production: true,
    },
    {
      subheader: 'Finance',
      items: [
        {
          title: 'Finance',
          path: PATH_DASHBOARD.finance.root,
          icon: ICONS.finance,
          children: [
            {
              title: 'Cashflow',
              path: PATH_DASHBOARD.finance.cashflow,
              code: 'CASHFLOW_PAGE',
            },
            {
              title: 'Cashflow Transfers',
              path: PATH_DASHBOARD.finance.cashflowTransfers,
              code: 'CASHFLOW_TRANSFERS_PAGE',
            },
            {
              title: 'Cashflow Categories',
              path: PATH_DASHBOARD.finance.cashflowCategories,
              code: 'CASHFLOW_CATEGORIES_PAGE',
            },
            {
              title: 'Payment Types',
              path: PATH_DASHBOARD.finance.paymentTypes,
              code: 'PAYMENT_TYPES_PAGE',
            },
          ],
          code: 'FINANCE_PAGE',
          production: true,
        },
      ],
      production: true,
    },
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Settings',
          path: PATH_DASHBOARD.setting.root,
          icon: ICONS.settings,
          children: [
            {
              title: 'General Settings',
              path: PATH_DASHBOARD.setting.general.root,
              children: [
                {
                  title: 'Business Locations',
                  path: PATH_DASHBOARD.setting.general.locations,
                  code: 'BUSINESS_LOCATIONS_PAGE',
                },
              ],
              code: 'GENERAL_SETTINGS_PAGE',
            },
            {
              title: 'Account Settings',
              path: PATH_DASHBOARD.setting.account.root,
              children: [
                {
                  title: 'My Account',
                  path: PATH_DASHBOARD.setting.account.ownAccount,
                  code: 'MY_ACCOUNT_PAGE',
                },
              ],
              code: 'ACCOUNT_SETTINGS_PAGE',
            },
          ],
          code: 'SETTINGS_PAGE',
        },
      ],
      production: true,
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [
        {
          title: 'app',
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },
        {
          title: 'e-commerce',
          path: PATH_DASHBOARD.general.ecommerce,
          icon: ICONS.ecommerce,
        },
        {
          title: 'analytics',
          path: PATH_DASHBOARD.general.analytics,
          icon: ICONS.analytics,
        },
        {
          title: 'banking',
          path: PATH_DASHBOARD.general.banking,
          icon: ICONS.banking,
        },
        {
          title: 'booking',
          path: PATH_DASHBOARD.general.booking,
          icon: ICONS.booking,
        },
      ],
      production: false,
    },
    {
      subheader: 'management',
      production: false,
      items: [
        // USER
        {
          title: 'user',
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            { title: 'profile', path: PATH_DASHBOARD.user.profile },
            { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'list', path: PATH_DASHBOARD.user.list },
            { title: 'create', path: PATH_DASHBOARD.user.new },
            { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
          production: false,
        },

        // E-COMMERCE
        {
          title: 'e-commerce',
          path: PATH_DASHBOARD.eCommerce.root,
          icon: ICONS.cart,
          children: [
            { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
            { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
            { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
            { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
            { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
            { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          ],
          production: false,
        },

        // INVOICE
        {
          title: 'invoice',
          path: PATH_DASHBOARD.invoice.root,
          icon: ICONS.invoice,
          children: [
            { title: 'list', path: PATH_DASHBOARD.invoice.list },
            { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
            { title: 'create', path: PATH_DASHBOARD.invoice.new },
            { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
          ],
          production: false,
        },

        // BLOG
        {
          title: 'blog',
          path: PATH_DASHBOARD.blog.root,
          icon: ICONS.blog,
          children: [
            { title: 'posts', path: PATH_DASHBOARD.blog.posts },
            { title: 'post', path: PATH_DASHBOARD.blog.demoView },
            { title: 'create', path: PATH_DASHBOARD.blog.new },
          ],
          production: false,
        },
      ],
    },

    // APP
    // ----------------------------------------------------------------------
    {
      subheader: 'app',
      items: [
        {
          title: 'mail',
          path: PATH_DASHBOARD.mail.root,
          icon: ICONS.mail,
          info: (
            <Label variant='outlined' color='error'>
              +32
            </Label>
          ),
        },
        { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
        {
          title: 'calendar',
          path: PATH_DASHBOARD.calendar,
          icon: ICONS.calendar,
        },
        { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
      ],
      production: false,
    },
  ]
    .map((item) => {
      if (BASE_URL.includes('localhosts')) {
        return item;
      } else {
        if (item.production) {
          return item;
          // const dataObj = {
          //   ...item,
          //   items: item.items
          //     .map((innerItem) => {
          //       return {
          //         ...innerItem,
          //         children: innerItem.children
          //           ?.filter((children) => permissionsList.includes(children?.code) || permissionsList.includes('SUPER_ADMIN'))
          //           ?.map((value) => {
          //             if (value?.children?.length > 0) {
          //               const childrenValuesList = value?.children?.filter((children) => permissionsList.includes(children?.code) || permissionsList.includes('SUPER_ADMIN'));
          //               return childrenValuesList?.length > 0
          //                 ? {
          //                     ...value,
          //                     children: value?.children?.filter((children) => permissionsList.includes(children?.code) || permissionsList.includes('SUPER_ADMIN')),
          //                   }
          //                 : null;
          //             } else {
          //               return value;
          //             }
          //           })
          //           ?.filter((value) => (value?.children ? value?.children?.length > 0 : value)),
          //       };
          //     })
          //     .filter((value) => value?.children?.length > 0),
          // };
          // return dataObj?.items?.length > 0 ? dataObj : null;
        } else {
          return null;
        }
      }
    })
    .filter((value) => value);

export default navConfig;
