import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Box, Typography, IconButton, Slider, MenuItem, Select, FormControl, InputLabel, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { useSelector, dispatch } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { getAllCallLogs, updateCallLogs, updateCallLogsMarks } from '../../../../redux/slices/socialRedux/contactCenterRedux';
import { capitalize, utcMoment } from '../../../../utils/common';
import DataGridTable from '../../../../components/table/DataGridTable';
import Iconify from '../../../../components/Iconify';

export default function AbandonCalls() {
  const pageName = 'Abandoned Calls with No Further Contact';
  const { themeStretch } = useSettings();
  const [callLogList, setCallLogList] = useState([]);
  const [abandonList, setAbandonList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [currentRecording, setCurrentRecording] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [volume, setVolume] = useState(1);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [duration, setDuration] = useState('00:00');
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const audioRef = useRef(null);

  const { data, addData, callLogs } = useSelector((state) => state?.contactCenter);

  // Get all call logs
  useEffect(() => {
    dispatch(getAllCallLogs());
  }, []);

  // Map and update call logs
  useEffect(() => {
    setCallLogList(
      callLogs?.map((value) => ({
        id: value?.id,
        recording: value?.recording,
        date: utcMoment(value?.startTime).format('YYYY-MM-DD'),
        time: utcMoment(value?.startTime).format('HH:mm:ss'),
        mobitelName: value?.agent?.mobitelName,
        customerNumber: value?.customerNumber,
        employeeName: value?.user?.name,
        marks: value?.marks,
        holdTime: value?.holdTime,
        callTime: value?.callTime,
        totalTime: value?.totalTime,
        agentMobileNumber: value?.agentMobileNumber,
        endDate: utcMoment(value?.endTime).format('YYYY-MM-DD'),
        endTime: utcMoment(value?.endTime).format('HH:mm:ss'),
        mobitelId: value?.mobitelId,
        status: value?.callStatus,
      }))
    );
  }, [callLogs]);

  // Refresh call logs when new data is added
  useEffect(() => {
    if (addData?.data) {
      dispatch(getAllCallLogs());
    }
  }, [addData, dispatch]);

  // Format time for display
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Find ABANDON calls with no further contacts
  useEffect(() => {
    const abandonCalls = callLogList.filter((log) => {
      // Find all calls made after this one for the same customer
      const subsequentCalls = callLogList.filter((subLog) => subLog.customerNumber === log.customerNumber && subLog.date > log.date);
      // Return only ABANDON calls with no subsequent logs
      return log.status === 'ABANDON' && subsequentCalls.length === 0;
    });
    setAbandonList(abandonCalls);
  }, [callLogList]);

  // Handle play and pause actions for recordings
  const handlePlayRecording = (index) => {
    if (callLogList[index]?.recording) {
      setCurrentRecording(callLogList[index]?.recording);
      setCurrentIndex(index);
      setError('');
    } else {
      setError('Recording not available');
    }
  };

  // Columns for ABANDON calls table
  const abandonColumns = abandonList.length
    ? Object.keys(abandonList[0])
        .map((value) => {
          if (value === 'recording') {
            return {
              accessorKey: value,
              header: 'Recording',
              Cell: ({ row }) => {
                const hasRecording = row?.original?.recording?.includes('.wav');
                return row?.original?.['status'] === 'CALL_COMPLETED' && hasRecording ? (
                  <Button variant='outlined' size='small' onClick={() => handlePlayRecording(row.index)} startIcon={<Iconify icon='ic:round-play-arrow' />} color='success'>
                    Play
                  </Button>
                ) : (
                  <Button variant='outlined' size='small' color='error' startIcon={<Iconify icon='healthicons:no' />}>
                    Not Answered
                  </Button>
                );
              },
            };
          }
          if (value === 'marks') {
            return {
              accessorKey: value,
              header: 'Marks',
              Cell: ({ row }) => `${row?.original?.[value]}%`,
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter((col) => col.accessorKey)
    : [];

  return (
    <Page title={`${pageName}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading={pageName} links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Call Center', href: '' }, { name: pageName }]} />

        <DataGridTable
          name='Abandoned Call List'
          data={abandonList}
          column={abandonColumns}
          isRowClickable={false}
          enablePinning={false}
          enableRowSelection={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          maxHeight={'50vh'}
        />
      </Container>
    </Page>
  );
}
