import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from '../../../../../../redux/store';
import { useEffect, useState } from 'react';
import { Grid, SwipeableDrawer } from '@mui/material';
import PaymentTypeBlock from './paymentCollectorComponents/paymentTypeBlock';
import NormalCollection from './paymentCollectorComponents/NormalCollection';
import Discounts from './paymentCollectorComponents/Discounts';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other} sx={{ textAlign: 'left', width: '100%' }}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function PaymentCollector({ data, setData, onClickSound, onChangeTab }) {
  const { data: paymentTypeList } = useSelector((state) => state?.paymentTypes);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const currencyList = [5000, 1000, 500, 100, 50, 20, 10, 5, 2, 1];

  const totalPayable = data?.orderInfo?.cart?.reduce((sum, cartItem) => {
    const pricingData = cartItem?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id);
    return sum + pricingData?.pricing * cartItem?.quantity;
  }, 0);

  const getTotalPaidAmount = (data?.paymentRecords?.payments || []).reduce((sum, value) => sum + Number(value?.amount || 0), 0);

  useEffect(() => {
    const processedPaymentList = paymentTypeList?.filter((value) => {
      return value?.saleTypeList?.split(',').includes(data?.deliveryInfo?.id.toString());
    });
    setData((currentDataState) => ({
      ...currentDataState,
      paymentRecords: {
        totalPayable: totalPayable,
        totalPaidAmount: getTotalPaidAmount,
        balance: Math.abs(totalPayable - getTotalPaidAmount),
        payments: processedPaymentList?.map((paymentData) => {
          return {
            ...paymentData,
            isIncrement: paymentData?.isIncrement === 1,
            amount: currentDataState?.paymentRecords?.payments?.find((value) => value?.id === paymentData?.id)?.amount || 0,
            couponData: currentDataState?.paymentRecords?.payments?.find((value) => value?.id === paymentData?.id)?.couponData || 0,
          };
        }),
      },
    }));
  }, [paymentTypeList, totalPayable, selectedPaymentType, getTotalPaidAmount]);

  const handlePriceChange = (directSet = false, amount, couponData = {}) => {
    setData((currentDataState) => ({
      ...currentDataState,
      paymentRecords: {
        ...currentDataState?.paymentRecords,
        payments: currentDataState?.paymentRecords?.payments?.map((paymentRecordIndex) => {
          if (paymentRecordIndex?.id === selectedPaymentType?.id) {
            if (paymentRecordIndex?.isIncrement) {
              return {
                ...paymentRecordIndex,
                amount: directSet ? Number(amount) : Number(paymentRecordIndex?.amount) + Number(amount),
                couponData: couponData?.code ? couponData : paymentRecordIndex?.couponData,
              };
            } else {
              return {
                ...paymentRecordIndex,
                amount: directSet ? Number(amount) : Number(paymentRecordIndex?.amount) - Number(amount),
                couponData: couponData?.code ? couponData : paymentRecordIndex?.couponData,
              };
            }
          }
          return {
            ...paymentRecordIndex,
            amount: paymentRecordIndex?.amount,
          };
        }),
      },
    }));
  };

  const getPaymentTypeTotalAmount = () => {
    return data?.paymentRecords?.payments?.find((value) => value?.id === selectedPaymentType?.id)?.amount || '-';
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Grid container justifyContent='center'>
          {data?.paymentRecords?.payments?.map((paymentData, menuIndex) => {
            const hideVoucherAndDiscount = data?.paymentRecords?.payments?.filter((value) => value?.amount > 0 && [2, 4].includes(value?.id));
            if (hideVoucherAndDiscount.length > 0 && [2, 4].includes(paymentData?.id)) {
              return null;
            }
            return (
              <Grid item key={menuIndex} md={4}>
                <PaymentTypeBlock
                  title={paymentData?.name}
                  description={paymentData?.description}
                  isIncrement={paymentData?.isIncrement}
                  price={paymentData?.amount}
                  addToCart={() => {
                    setSelectedPaymentType(paymentData);
                    setIsModelOpen(true);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <SwipeableDrawer
          anchor={'right'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '60%',
              height: '90%',
              marginTop: '3%',
              borderRadius: '10px 0 0 10px',
            },
          }}
        >
          {[2, 4].includes(selectedPaymentType?.id) ? (
            <Discounts
              getPaymentTypeTotalAmount={getPaymentTypeTotalAmount}
              handlePriceChange={handlePriceChange}
              setIsModelOpen={setIsModelOpen}
              data={data}
              getTotalPaidAmount={getTotalPaidAmount}
              selectedPaymentType={selectedPaymentType}
              currencyList={currencyList}
            />
          ) : (
            <NormalCollection
              getPaymentTypeTotalAmount={getPaymentTypeTotalAmount}
              handlePriceChange={handlePriceChange}
              setIsModelOpen={setIsModelOpen}
              data={data}
              getTotalPaidAmount={getTotalPaidAmount}
              selectedPaymentType={selectedPaymentType}
              currencyList={currencyList}
            />
          )}
        </SwipeableDrawer>
      </Grid>
    </>
  );
}
