import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import LocationManagement from '../pages/dashboard/settings/location';
import UserManagementAccount from '../pages/dashboard/hr/user/userAccount';
import TimeLog from '../pages/dashboard/hr/timeLog';
import SalesList from '../pages/dashboard/sales/salesList';
import SalaryManagement from '../pages/dashboard/hr/salary';
import PaySheetIndex from '../pages/dashboard/hr/paysheet/paySheetIndex';
import SupplierManagement from '../pages/dashboard/suppliers/suppliers';
import FoodList from '../pages/dashboard/food/foodList';
import FoodCategoryManagement from '../pages/dashboard/food/foodCategory';
import { dispatch } from '../redux/store';
import { getLocations } from '../redux/slices/settingsManagementRedux/locationsRedux';
import { getUnits } from '../redux/slices/stockManagementRedux/unitRedux';
import EnterPrices from '../pages/dashboard/purchasing/enterPrices';
import AddToCart from '../pages/dashboard/purchasing/addToCart';
import PurchaseNotes from '../pages/dashboard/purchasing/purchaseNotes';
import Receiving from '../pages/dashboard/purchasing/receiving';
import FoodEditor from '../pages/dashboard/food/foodEditor';
import StockList from '../pages/dashboard/stock/inventoryManagement/stockList';
import SingleStockItem from '../pages/dashboard/stock/inventoryManagement/singleStockItem';
import Sender from '../pages/dashboard/stock/transferManagement/sender';
import ViewTransfer from '../pages/dashboard/stock/transferManagement/viewTransfer';
import POS from '../pages/dashboard/pos/POS';
import { SetToCookingStatus } from '../pages/dashboard/kitchen/setToCookingStatus';
import { SetToStoreStatus } from '../pages/dashboard/kitchen/setToStoreStatus';
import { CookingProcess } from '../pages/dashboard/kitchen/cookingProcess';
import KitchenDisplay from '../pages/dashboard/kitchen/kitchenDisplay';
import AssetsList from '../pages/dashboard/assets/assetsList';
import ViewStockOrders from '../pages/dashboard/stock/stockOrder/viewStockOrders';
import CreateStockOrders from '../pages/dashboard/stock/stockOrder/createStockOrders';
import AssetCategories from '../pages/dashboard/assets/assetCategories';
import Cashflow from '../pages/dashboard/finance/cashflow';
import PaymentTypes from '../pages/dashboard/finance/paymentTypes';
import CashflowTransfers from '../pages/dashboard/finance/cashflowTransfers';
import StockAvailabilityChecker from '../pages/dashboard/stock/stockAvailabilityChecker';
import CustomerList from '../pages/dashboard/sales/customerList';
import Rost from '../pages/dashboard/rost/rost';
import InspectionTypeList from '../pages/dashboard/hr/inspection/inspectionTypeList';
import InspectionTemplateList from '../pages/dashboard/hr/inspection/inspectionTemplateList';
import InspectionExamin from '../pages/dashboard/hr/inspection/inspectionExamin';
import LeaveCalender from '../pages/dashboard/hr/user/components/leaveCalender';
import Breaker from '../pages/dashboard/stock/breaker';
import { getPermissions } from '../redux/slices/commonRedux/commonRedux';
import HomeDashboard from '../pages/dashboard/home/homeDashboard';
import CashFlowCategories from '../pages/dashboard/finance/cashFlowCategories';
import Aurudu from '../pages/aurudu/aurudu';
import Coupons from '../pages/dashboard/marketing/coupons';
import { accessVerify } from '../utils/common';
import PermissionRequired from '../pages/errorPages/permissionRequired';
import MetaDashboard from '../pages/dashboard/home/metaDashboard';
import WasteManager from '../pages/dashboard/stock/wasteManager';
import UsedStockManager from '../pages/dashboard/stock/usedStockManager';
import ProductTags from '../pages/dashboard/product/productTags';
import ProductCategories from '../pages/dashboard/product/productCategories';
import ProductAttributes from '../pages/dashboard/product/productAttributes';
import ProductList from '../pages/dashboard/product/productList';
import ProductEditor from '../pages/dashboard/product/productEditor/productEditor';
import CallCenter from '../pages/dashboard/social/callCenter/callcenter';
import CallLogs from '../pages/dashboard/social/callCenter/callLogs';
import AbandonCalls from '../pages/dashboard/social/callCenter/abandonCalls';
import MetaConnect from '../pages/dashboard/social/meta/metaConnect';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getLocations());
    // dispatch(getUnits());
  }, []);

  return useRoutes([
    {
      path: 'pay',
      children: [
        {
          path: 'sheet/:token',
          element: <PaySheetIndex />,
        },
      ],
    },
    {
      path: 'aurudu',
      element: <Aurudu />,
    },
    {
      path: 'aurudu/:uuid',
      element: <Aurudu />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <HomeDashboard /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralEcommerce /> },
        { path: 'locations', element: <LocationManagement /> },
        { path: 'pos', element: <POS /> },
        {
          path: 'social',
          children: [
            {
              path: 'meta',
              children: [
                { path: 'MetaConnect', element: accessVerify('CHAT_USER') ? <MetaConnect /> : <PermissionRequired /> },
                { path: 'chat', element: accessVerify('CHAT_USER') ? <Chat /> : <PermissionRequired /> },
                { path: 'chat/:userId', element: accessVerify('CHAT_USER') ? <Chat /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'callCenter',
              children: [
                { path: 'manage', element: accessVerify('CHAT_USER') ? <CallCenter /> : <PermissionRequired /> },
                { path: 'logs', element: accessVerify('CHAT_USER') ? <CallLogs /> : <PermissionRequired /> },
                { path: 'abandonCalls', element: accessVerify('CHAT_USER') ? <AbandonCalls /> : <PermissionRequired /> },
              ],
            },
          ],
        },
        {
          path: 'products',
          children: [
            { path: 'list', element: <ProductList /> },
            { path: 'editor/:productId/:parentId', element: <ProductEditor /> },
            { path: 'category', element: <ProductCategories /> },
            { path: 'tags', element: <ProductTags /> },
            { path: 'attributes', element: <ProductAttributes /> },
            // { path: 'variations', element: <ProductVariations /> },
            // { path: 'manage/:productId', element: <ProductEditor /> },
          ],
        },
        { path: 'home', element: accessVerify('DASHBOARD_ANALYTICS_PAGE') ? <HomeDashboard /> : <PermissionRequired /> },
        { path: 'meta/:type', element: accessVerify('DASHBOARD_META_DASHBOARD') ? <MetaDashboard /> : <PermissionRequired /> },
        {
          path: 'foods',
          children: [
            { path: 'category/:type', element: accessVerify('FOOD_CATEGORY_PAGE') ? <FoodCategoryManagement /> : <PermissionRequired /> },
            { path: 'types/:type', element: accessVerify('FOOD_PAGE') ? <FoodList /> : <PermissionRequired /> },
            { path: 'food/:type/manage/:foodId', element: accessVerify('FOOD_UPDATE') ? <FoodEditor /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'suppliers',
          children: [
            { path: 'stock', element: accessVerify('STOCK_SUPPLIER_PAGE') ? <SupplierManagement isStockSupplier={true} /> : <PermissionRequired /> },
            { path: 'assetsAndServices', element: accessVerify('ASSET_SERVICE_SUPPLIER_PAGE') ? <SupplierManagement isStockSupplier={false} /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'purchasing',
          children: [
            { path: 'enterPrices', element: accessVerify('CALL_AND_GET_PRICES_PAGE') ? <EnterPrices /> : <PermissionRequired /> },
            { path: 'purchaseNotes', element: accessVerify('PURCHASE_NOTE_PAGE') ? <PurchaseNotes /> : <PermissionRequired /> },
            { path: 'addToCart', element: accessVerify('ADD_TO_CART_PAGE') ? <AddToCart /> : <PermissionRequired /> },
            { path: 'receiving', element: accessVerify('GOODS_RECEIVING_PAGE') ? <Receiving /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'kitchen',
          children: [
            { path: 'cooking', element: accessVerify('COOKING_STATUS_PAGE') ? <SetToCookingStatus /> : <PermissionRequired /> },
            { path: 'store', element: accessVerify('COOKING_STATUS_PAGE') ? <SetToStoreStatus /> : <PermissionRequired /> },
            { path: 'cookingProcess', element: accessVerify('COOKING_PROCESS_PAGE') ? <CookingProcess /> : <PermissionRequired /> },
            { path: 'display', element: accessVerify('KITCHEN_DISPLAY_PAGE') ? <KitchenDisplay /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'marketing',
          children: [{ path: 'coupons/:type', element: accessVerify('COUPONS_PAGE') ? <Coupons /> : <PermissionRequired /> }],
        },
        {
          path: 'stock',
          children: [
            { path: 'view-all/:showPOSOnly', element: accessVerify('VIEW_STOCK_PAGE') ? <StockList /> : <PermissionRequired /> },
            { path: 'breaker', element: accessVerify('STOCK_BREAKER_PAGE') ? <Breaker /> : <PermissionRequired /> },
            { path: 'wasteManager', element: accessVerify('STOCK_BREAKER_PAGE') ? <WasteManager /> : <PermissionRequired /> },
            { path: 'usedStockManager', element: accessVerify('STOCK_BREAKER_PAGE') ? <UsedStockManager /> : <PermissionRequired /> },
            {
              path: 'transfer',
              children: [
                { path: 'view', element: accessVerify('STOCK_TRANSFER_PAGE') ? <ViewTransfer /> : <PermissionRequired /> },
                { path: 'sender', element: accessVerify('STOCK_SENDER_PAGE') ? <Sender /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'stockOrder',
              children: [
                { path: 'view', element: accessVerify('STOCK_ORDER_LIST_PAGE') ? <ViewStockOrders /> : <PermissionRequired /> },
                { path: 'create', element: accessVerify('CREATE_STOCK_ORDER_LIST_PAGE') ? <CreateStockOrders /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'availability',
              children: [{ path: 'checker', element: accessVerify('AVAILABILITY_CHECKER_PAGE') ? <StockAvailabilityChecker /> : <PermissionRequired /> }],
            },
            { path: 'single-stock-item/:id', element: accessVerify('VIEW_STOCK_VIEW_SINGLE_ITEM') ? <SingleStockItem /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'sales',
          children: [
            { path: 'saleList', element: accessVerify('SALES_PAGE') ? <SalesList /> : <PermissionRequired /> },
            { path: 'customerList', element: accessVerify('CUSTOMERS_PAGE') ? <CustomerList /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'hr',
          children: [
            {
              path: 'employees',
              children: [
                { path: 'list/:type', element: accessVerify('USER_LIST_PAGE') ? <UsersList /> : <PermissionRequired /> },
                { path: 'account/:id', element: accessVerify('USER_PROFILE_VIEW') ? <UserManagementAccount /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'salary',
              children: [
                { path: 'timeLog', element: <TimeLog /> },
                { path: 'generateSalary', element: accessVerify('USER_PROFILE_VIEW') ? <SalaryManagement /> : <PermissionRequired /> },
              ],
            },
            {
              path: 'inspection',
              children: [
                { path: 'typeList', element: accessVerify('INSPECTION_TYPE_PAGE') ? <InspectionTypeList /> : <PermissionRequired /> },
                {
                  path: 'inspectionTemplateList',
                  element: accessVerify('INSPECTION_TEMPLATE_PAGE') ? <InspectionTemplateList /> : <PermissionRequired />,
                },
                {
                  path: 'inspectionExamin',
                  element: accessVerify('INSPECTION_EXAMIN_PAGE') ? <InspectionExamin /> : <PermissionRequired />,
                },
              ],
            },
            {
              path: 'rost',
              children: [{ path: 'manager', element: accessVerify('ROST_MANAGER_PAGE') ? <Rost /> : <PermissionRequired /> }],
            },
            {
              path: 'leave',
              children: [{ path: 'manager/:id', element: accessVerify('LEAVE_MANAGER_PAGE') ? <LeaveCalender /> : <PermissionRequired /> }],
            },
          ],
        },
        {
          path: 'assets',
          children: [
            { path: 'list', element: accessVerify('ASSETS_LIST_PAGE') ? <AssetsList /> : <PermissionRequired /> },
            { path: 'categories', element: accessVerify('ASSET_CATEGORIES_PAGE') ? <AssetCategories /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'finance',
          children: [
            { path: 'cashflow', element: accessVerify('CASHFLOW_PAGE') ? <Cashflow /> : <PermissionRequired /> },
            { path: 'cashflowCategories', element: accessVerify('CASHFLOW_CATEGORIES_PAGE') ? <CashFlowCategories /> : <PermissionRequired /> },
            { path: 'cashflowTransfers', element: accessVerify('CASHFLOW_TRANSFERS_PAGE') ? <CashflowTransfers /> : <PermissionRequired /> },
            { path: 'paymentTypes', element: accessVerify('PAYMENT_TYPES_PAGE') ? <PaymentTypes /> : <PermissionRequired /> },
          ],
        },
        {
          path: 'settings',
          children: [
            {
              path: 'general',
              children: [{ path: 'locations', element: accessVerify('BUSINESS_LOCATIONS_PAGE') ? <LocationManagement /> : <PermissionRequired /> }],
            },
            {
              path: 'account',
              children: [{ path: 'userAccount/:id', element: <UserManagementAccount /> }],
            },
          ],
        },
        {
          path: 'chatComponents/:id',
          element: <Chat />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/template/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/template/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/template/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/template/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/template/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/template/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/template/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/template/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/template/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/template/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/template/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/template/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/template/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/template/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/template/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/template/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/template/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/template/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/template/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/template/UserList')));
const UsersList = Loadable(lazy(() => import('../pages/dashboard/hr/user/userList')));
const UserAccount = Loadable(lazy(() => import('../pages/template/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/template/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/social/meta/chat/Chat')));
const Mail = Loadable(lazy(() => import('../pages/template/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/template/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/template/Kanban')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/template/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/errorPages/maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/template/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/template/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/errorPages/page500')));
const NotFound = Loadable(lazy(() => import('../pages/errorPages/page404')));
