import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import ImageResize from 'quill-image-resize-module-react';

import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import { uploadFiles } from '../../utils/common';
import uuidv4 from '../../utils/uuidv4';
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);
import './QuillTextEditor.css';
import { toast } from 'react-toastify';

function QuillTextEditor({ label, value, onChange }) {
  const handleChange = (content) => {
    onChange(content);
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <ReactQuill
        theme='snow'
        value={value}
        onChange={handleChange}
        modules={QuillTextEditor.modules}
        formats={QuillTextEditor.formats}
        placeholder='Enter description...'
        style={{ minHeight: '200px' }}
      />
    </div>
  );
}

QuillTextEditor.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
  },
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        let toastId;
        toastId = toast.loading('Uploading Image...');

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESENT);
        formData.append('cloud_name', process.env.REACT_APP_CLOUDINARY_CLOUDNAME);

        fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}/image/upload`, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            const originalUrl = result.secure_url.replace('/upload/', '/upload/q_auto:eco,c_crop,g_custom/');

            uploadFiles(originalUrl, result?.public_id, uuidv4(), 'productImages', ['avif', 'png', 'jpg'])
              .then((value) => {
                toast.update(toastId, {
                  render: 'Image uploaded successfully',
                  type: toast.TYPE.SUCCESS,
                  autoClose: 2000,
                  closeButton: true,
                  isLoading: false,
                });
                resolve(value?.data?.publicUrl);
              })
              .catch((error) => {
                toast.update(toastId, {
                  render: 'Image upload failed!',
                  type: toast.TYPE.ERROR,
                  autoClose: 2000,
                  closeButton: true,
                  isLoading: false,
                });
                reject(error);
              });
          })
          .catch((error) => {
            reject('Upload failed');
          });
      });
    },
  },
};

QuillTextEditor.formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video'];

export default QuillTextEditor;
