import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, IconButton, Stack, SwipeableDrawer, TextField, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useSettings from '../../../../../hooks/useSettings';
import Page from '../../../../../components/Page';
import Iconify from '../../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getSpecificUserData } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { addDocument, updateDocument } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userDocumentUploadRedux';
import Label from '../../../../../components/Label';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { accessVerify, capitalize, ImageUploadAndPreviewButton, utcMomentFormatDate, utcMomentFormatTime } from '../../../../../utils/common';
import { getDocumentTypes } from '../../../../../redux/slices/settingsManagementRedux/documentTypeRedux';

export default function UserUploadedFiles({ userId }) {
  const dispatch = useDispatch();

  const dataModel = {
    userImage: null,
    documentTypeId: null,
  };

  const { loggedInUser } = useSelector((state) => state.user);
  const { themeStretch } = useSettings();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);

  const { selectedUser } = useSelector((state) => state.user);
  const { data: documentTypes } = useSelector((state) => state.documentType);
  const { addData } = useSelector((state) => state.document);

  useEffect(() => {
    dispatch(getDocumentTypes());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(getSpecificUserData(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (selectedUser?.[0]?.userDocument) {
      setIsModelOpen(false);
      setDocumentsList(
        selectedUser?.[0]?.userDocument.map((value) => ({
          Id: value.id,
          date: value?.dateTime || '-',
          time: value?.dateTime || '-',
          documentType: documentTypes.find((type) => type.id === value.documentTypeId)?.name || 'Unknown',
          url: value?.url || '-',
          status: value?.status || '-',
        }))
      );
      setIsModelOpen(false);
    }
  }, [selectedUser]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const manageModel = (modelData, type) => {
    setIsModelOpen((prev) => !prev);
    setIsAdd(type === 'add');
    setSelectedDataObj(type === 'add' ? dataModel : modelData);
  };

  const onSubmit = () => {
    try {
      const tempDataObj = {
        url: selectedDataObj.userImage || '',
        documentTypeId: selectedDataObj.documentTypeId || '',
        userId: selectedUser?.[0]?.id || '',
      };
      if (isAdd) {
        dispatch(addDocument([tempDataObj]));
      }
      // setSelectedDataObj(dataModel);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData, dispatch, userId]);

  const columns = useMemo(() => {
    if (!documentsList?.[0]) return [];
    return Object.keys(documentsList[0]).map((key) => {
      if (key === 'date') {
        return {
          accessorKey: key,
          header: capitalize(key),
          Cell: ({ cell }) => {
            return utcMomentFormatDate(cell.getValue()).toString();
          },
        };
      } else if (key === 'time') {
        return {
          accessorKey: key,
          header: capitalize(key),
          Cell: ({ cell }) => {
            return utcMomentFormatTime(cell.getValue()).toString();
          },
        };
      } else if (key === 'url') {
        return {
          accessorKey: key,
          header: capitalize(key),
          Cell: ({ cell }) => (
            <a href={cell.getValue()} target='_blank' rel='noreferrer'>
              <img src={cell.getValue()} alt='Document' style={{ maxWidth: '100px', maxHeight: '100px' }} />
            </a>
          ),
        };
      } else if (key === 'status') {
        return {
          accessorKey: key,
          header: capitalize(key),
          Cell: ({ cell }) => (
            <Label color={cell.getValue() === 'approved' ? 'success' : cell.getValue() === 'declined' ? 'error' : 'warning'} sx={{ textTransform: 'capitalize' }}>
              {cell.getValue()}
            </Label>
          ),
        };
      } else {
        return {
          accessorKey: key,
          header: capitalize(key),
        };
      }
    });
  }, [documentsList]);

  const [rowSelection, setRowSelection] = useState(false);

  return (
    <Page title='User Uploaded Files'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading=''
          links={[]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel(dataModel, 'add')}>
              Add Document
            </Button>
          }
        />

        <DataGridTable
          name='User uploaded documents'
          data={documentsList}
          column={columns}
          isLoading={!documentsList.length}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowActions={accessVerify('USER_DOCUMENTS_STATUS_UPDATE')}
          renderRowActionItems={(value) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              {['approved', 'declined'].includes(value.original.status) && (
                <Tooltip title='Set to Pending'>
                  <IconButton
                    color='warning'
                    onClick={() =>
                      dispatch(
                        updateDocument({
                          ...value.original,
                          status: 'pending',
                          documentTypeId: value.original.documentTypeId,
                        })
                      )
                    }
                  >
                    <PendingIcon />
                  </IconButton>
                </Tooltip>
              )}
              {['pending', 'declined'].includes(value.original.status) && (
                <Tooltip title='Set to Approved'>
                  <IconButton
                    color='success'
                    onClick={() =>
                      dispatch(
                        updateDocument({
                          ...value.original,
                          status: 'approved',
                          documentTypeId: value.original.documentTypeId,
                        })
                      )
                    }
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
              {['pending', 'approved'].includes(value.original.status) && (
                <Tooltip title='Set to Declined'>
                  <IconButton
                    color='error'
                    onClick={() =>
                      dispatch(
                        updateDocument({
                          ...value.original,
                          status: 'declined',
                          documentTypeId: value.original.documentTypeId,
                        })
                      )
                    }
                  >
                    <ThumbDownAltIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        />

        <SwipeableDrawer
          anchor='top'
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant='h5'>{isAdd ? 'Add' : 'Edit'} Document</Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <ImageUploadAndPreviewButton
                    hideTopic={true}
                    fileName={`${loggedInUser?.name}-document`}
                    width={1240}
                    height={1754}
                    format={['png']}
                    label='User Uploaded Documents'
                    keyName='userImage'
                    folderName='userUploadedDocuments'
                    isImageUploading={isImageUploading}
                    setIsImageUploading={setIsImageUploading}
                    updateEditingData={updateEditingData}
                    selectedDataObj={selectedDataObj}
                  />
                </Card>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        const selectedType = documentTypes.find((type) => type.id === newValue.value);
                        updateEditingData('documentTypeId', selectedType?.id);
                      }}
                      options={documentTypes?.map((value) => ({
                        label: value.name,
                        value: value.id,
                      }))}
                      value={{
                        label: documentTypes?.find((type) => type.id === selectedDataObj.documentTypeId)?.name || 'Document Type Not Found',
                        value: selectedDataObj.documentTypeId,
                      }}
                      renderInput={(params) => <TextField label='Document Types' {...params} />}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton onClick={() => onSubmit(selectedDataObj)} variant='contained' size='large' style={{ width: '100%' }}>
                    {isAdd ? 'Add Document' : 'Save Changes'}
                  </LoadingButton>
                  <Button color='info' variant='outlined' size='large' style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
