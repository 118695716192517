import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Container, IconButton, TextField, Paper, Box, Typography, Grid } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { getAllContactList, updateLinkedUser, updateMobileNumberStatus, updateMobitelName, updatePriorityList } from '../../../../redux/slices/socialRedux/contactCenterRedux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getUsers } from '../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';

export default function CallCenter() {
  const pageName = 'Mobitel Call Center Manager';
  const { themeStretch } = useSettings();
  const [numberList, setNumberList] = useState([]);
  const [dataUpdaterCalled, setDataUpdaterCalled] = useState(false);
  const { data, addData } = useSelector((state) => state?.contactCenter);

  let userListData;
  ({ data: userListData } = useSelector((state) => state.user));

  useEffect(() => {
    dispatch(getAllContactList());
    dispatch(getUsers(1));
  }, []);

  useEffect(() => {
    setDataUpdaterCalled(false);
    setNumberList(data?.filter((value) => value?.priority));
  }, [data]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(numberList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setNumberList(items);
  };

  useEffect(() => {
    if (addData?.data && !dataUpdaterCalled) {
      dispatch(getAllContactList());
      setDataUpdaterCalled(true);
    }
  }, [addData, dispatch]);

  return (
    <Page title={`${pageName}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${pageName}`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Call Center',
              href: '',
            },
            { name: `${pageName}` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='material-symbols:save-sharp' />}
              onClick={() => {
                dispatch(updatePriorityList(numberList?.map((value, index) => ({ phone: value?.number, priority: index + 1 }))));
              }}
            >
              Update Priority
            </Button>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId='contacts'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {numberList?.map((value, index) => (
                      <Draggable key={value?.number} draggableId={value?.number} index={index}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            elevation={3}
                            sx={{
                              padding: '16px',
                              marginBottom: '10px',
                              borderRadius: '10px',
                              backgroundColor: value?.status === 'IN' ? '#eaf7ec' : '#f7eaea',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Box display='flex' alignItems='center'>
                              <IconButton
                                onClick={() => {
                                  dispatch(updateMobileNumberStatus({ phone: value?.number, status: value?.status === 'IN' ? 0 : 1 }));
                                }}
                                sx={{
                                  color: value?.status === 'IN' ? 'green' : 'red',
                                  marginRight: '3px',
                                }}
                              >
                                <Iconify icon={'ph:power-fill'} width={30} height={30} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  const nameToReplace = prompt('Enter mobitel connection name', value?.mobitelName);
                                  if (nameToReplace?.length > 0) {
                                    dispatch(updateMobitelName({ agentId: value?.agentId, name: nameToReplace }));
                                  }
                                }}
                                sx={{
                                  color: '#ff9600',
                                  marginRight: '16px',
                                }}
                              >
                                <Iconify icon={'material-symbols:edit-outline'} width={30} height={30} />
                              </IconButton>

                              <Box>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                  {value?.mobitelName} ({value?.number})
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                  Priority: {value?.priority}
                                </Typography>
                              </Box>
                            </Box>

                            <Autocomplete
                              onChange={(event, newValue) => {
                                dispatch(updateLinkedUser({ agentId: value?.agentId, userId: newValue?.value || null }));
                                setNumberList(numberList?.map((item) => (item.number === value.number ? { ...item, userId: newValue?.value } : item)));
                              }}
                              options={userListData?.map((userData) => ({
                                label: userData.name,
                                value: userData.id,
                              }))}
                              value={{
                                label: userListData?.find((val) => val.id === value.userId)?.name || 'User Not Selected!',
                                value: value.userId,
                              }}
                              renderInput={(params) => <TextField {...params} label='Assign User' variant='outlined' size='small' />}
                              sx={{ width: 240 }}
                            />
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Container>
    </Page>
  );
}
