import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Box, Typography, IconButton, Slider, MenuItem, Select, FormControl, InputLabel, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { useSelector, dispatch } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { getAllCallLogs, updateCallLogs, updateCallLogsMarks } from '../../../../redux/slices/socialRedux/contactCenterRedux';
import { capitalize, utcMoment } from '../../../../utils/common';
import DataGridTable from '../../../../components/table/DataGridTable';
import Iconify from '../../../../components/Iconify';

export default function CallLogs() {
  const pageName = 'Mobitel Call Logs';
  const { themeStretch } = useSettings();
  const [callLogList, setCallLogList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [currentRecording, setCurrentRecording] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [volume, setVolume] = useState(1);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [duration, setDuration] = useState('00:00');
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const audioRef = useRef(null);

  const { data, addData, callLogs } = useSelector((state) => state?.contactCenter);

  useEffect(() => {
    dispatch(getAllCallLogs());
  }, []);

  useEffect(() => {
    setCallLogList(
      callLogs?.map((value) => ({
        id: value?.id,
        recording: value?.recording,
        date: utcMoment(value?.startTime).format('YYYY-MM-DD'),
        time: utcMoment(value?.startTime).format('HH:mm:ss'),
        mobitelName: value?.agent?.mobitelName,
        employeeName: value?.user?.name,
        marks: value?.marks,
        holdTime: value?.holdTime,
        callTime: value?.callTime,
        totalTime: value?.totalTime,
        agentMobileNumber: value?.agentMobileNumber,
        customerNumber: value?.customerNumber,
        endDate: utcMoment(value?.endTime).format('YYYY-MM-DD'),
        endTime: utcMoment(value?.endTime).format('HH:mm:ss'),
        mobitelId: value?.mobitelId,
        status: value?.callStatus,
      }))
    );
  }, [callLogs]);

  useEffect(() => {
    if (addData?.data) {
      dispatch(getAllCallLogs());
    }
  }, [addData, dispatch]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handlePlayRecording = (index) => {
    if (callLogList[index]?.recording) {
      setCurrentRecording(callLogList[index]?.recording);
      setCurrentIndex(index);
      setError('');
    } else {
      setError('Recording not available');
    }
  };

  const handleAudioEnd = () => {
    let nextIndex = currentIndex + 1;

    while (nextIndex < callLogList.length && !callLogList[nextIndex]?.recording) {
      nextIndex++;
    }

    if (nextIndex < callLogList.length) {
      setCurrentIndex(nextIndex);
      setCurrentRecording(callLogList[nextIndex]?.recording);
    } else {
      setCurrentRecording(null);
      setCurrentIndex(-1);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTimeInSeconds = audioRef.current.currentTime;
      const durationInSeconds = audioRef.current.duration;
      setCurrentTime(formatTime(currentTimeInSeconds));
      setDuration(formatTime(durationInSeconds));
      setProgress((currentTimeInSeconds / durationInSeconds) * 100);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackSpeed;
      audioRef.current.volume = volume;
    }
  }, [playbackSpeed, volume, currentRecording]);

  const columns = callLogList.length
    ? Object.keys(callLogList[0])
        .map((value) => {
          if (value === 'recording') {
            return {
              accessorKey: value,
              header: 'Recording',
              Cell: ({ row }) => {
                const hasRecording = row?.original?.recording?.includes('.wav');
                return row?.original?.['status'] === 'CALL_COMPLETED' && hasRecording ? (
                  callLogList[currentIndex]?.id === row?.original?.['id'] && !audioRef.current?.paused ? (
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => {
                        audioRef.current.pause();
                      }}
                      color={'warning'}
                      startIcon={<Iconify icon='ic:round-pause' />}
                    >
                      Pause
                    </Button>
                  ) : (
                    <Button variant='outlined' size='small' onClick={() => handlePlayRecording(row.index)} startIcon={<Iconify icon='ic:round-play-arrow' />} color={'success'}>
                      Play
                    </Button>
                  )
                ) : (
                  <Button variant='outlined' size='small' color={'error'} startIcon={<Iconify icon='healthicons:no' />}>
                    Not Answered
                  </Button>
                );
              },
            };
          }
          if (value === 'marks') {
            return {
              accessorKey: value,
              header: 'Marks',
              Cell: ({ row }) => {
                return `${row?.original?.[value]}%`;
              },
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter((col) => col.accessorKey)
    : [];

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDate('');
  };

  const handleUpdateData = () => {
    dispatch(updateCallLogs({ fromDate: utcMoment(selectedDate).add(1, 'day').format('YYYY-MM-DD'), toDate: utcMoment(selectedDate).add(2, 'day').format('YYYY-MM-DD') }));
    handleCloseModal();
  };

  return (
    <Page title={`${pageName}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={pageName}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Call Center', href: '' }, { name: pageName }]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='material-symbols:save-sharp' />} onClick={handleOpenModal}>
              Manual Data Update
            </Button>
          }
        />

        {error && <Typography color='error'>{error}</Typography>}

        {currentRecording && (
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
            padding='5px'
            boxShadow={3}
            bgcolor='white'
            borderRadius='8px'
            marginBottom={2}
            position='relative'
          >
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant='p'
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    color: '#838383',
                  }}
                >
                  {`Now Playing: (${callLogList[currentIndex]?.id}) ${callLogList[currentIndex]?.employeeName} (${callLogList[currentIndex]?.mobitelId})`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} display='flex' alignItems='center' justifyContent='center'>
                <IconButton
                  onClick={() => {
                    if (audioRef.current.paused) {
                      audioRef.current.play();
                    } else {
                      audioRef.current.pause();
                    }
                  }}
                >
                  {audioRef.current?.paused ? <Iconify icon='ic:round-play-arrow' /> : <Iconify icon='ic:round-pause' />}
                </IconButton>
              </Grid>
              <Grid item xs={12} md={8}>
                <audio ref={audioRef} src={currentRecording} onEnded={handleAudioEnd} onTimeUpdate={handleTimeUpdate} controls={false} autoPlay />
                <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' marginTop='10px' padding='0 10px'>
                  <Typography style={{ color: '#555' }}>{currentTime}</Typography>
                  <Slider value={progress} onChange={(_, newValue) => (audioRef.current.currentTime = (newValue / 100) * audioRef.current.duration)} style={{ width: '100%', margin: '0 10px' }} />
                  <Typography style={{ color: '#555' }}>{duration}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={1}>
                <FormControl variant='outlined' style={{ width: '100%' }}>
                  <InputLabel>Speed</InputLabel>
                  <Select label='Speed' value={playbackSpeed} onChange={(e) => setPlaybackSpeed(e.target.value)} style={{ width: '100%' }}>
                    <MenuItem value={0.5}>0.5x</MenuItem>
                    <MenuItem value={1}>1x (Normal)</MenuItem>
                    <MenuItem value={1.5}>1.5x</MenuItem>
                    <MenuItem value={2}>2x</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <FormControl variant='outlined' style={{ width: '100%' }}>
                  <InputLabel>Marks</InputLabel>
                  <Select
                    label='Speed'
                    value={callLogList[currentIndex]?.marks}
                    onChange={(e) => {
                      setCallLogList((prev) => {
                        const updatedList = [...prev];
                        updatedList[currentIndex].marks = e.target.value;
                        return updatedList;
                      });
                      dispatch(updateCallLogsMarks({ id: callLogList[currentIndex]?.id, marks: e.target.value }));
                    }}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={25}>25%</MenuItem>
                    <MenuItem value={50}>50%</MenuItem>
                    <MenuItem value={75}>75%</MenuItem>
                    <MenuItem value={100}>100%</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}

        <DataGridTable
          name={`${pageName} List`}
          data={callLogList}
          column={columns}
          isRowClickable={false}
          enablePinning={false}
          enableRowSelection={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          maxHeight={'50vh'}
        />

        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Manual Data Update</DialogTitle>
          <DialogContent>
            <TextField
              label='Select Date'
              type='date'
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleUpdateData} color='primary'>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
