import Router from './routes';
import ThemeProvider from './theme';
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { useSelector } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PosDrawer from './pages/dashboard/pos/components/subcomponents/mainPos/posDrawer';
import POSClose from './pages/dashboard/pos/POSClose';

export default function App() {
  const { isLoading, loadingNumber } = useSelector((state) => state.loading);

  useEffect(() => {
    isLoading ? NProgress.start() : loadingNumber === 0 && NProgress.done();
  }, [isLoading, loadingNumber]);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
                <ToastContainer />
                <PosDrawer />
                <POSClose />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
