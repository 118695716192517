import React, { useEffect, useState } from 'react';
import { Card, Stack, Typography, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { handleOpenInNewTab } from '../../../../../utils/common';

export default function ProductVariation({ selectedData, setSelectedDataObj }) {
  const [variationList, setVariationList] = useState([]);

  useEffect(() => {
    if (selectedData?.childrenProducts) {
      setVariationList(selectedData?.childrenProducts);
    }
  }, [selectedData]);

  return (
    <Card sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center' padding='10px 0'>
        <Typography variant='subtitle1'>Variation Data</Typography>
        <IconButton
          color='primary'
          onClick={() => {
            handleOpenInNewTab(`/dashboard/products/editor/new/${selectedData?.id}`);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Stack spacing={2}>
          {variationList?.map((value, index) => (
            <Card
              key={index}
              sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
              onClick={() => {
                handleOpenInNewTab(`/dashboard/products/editor/${value?.id}/${value?.parentId}`);
              }}
            >
              <Typography variant='body1'>{value?.name}</Typography>
              <IconButton color='primary'>
                <OpenInNewIcon />
              </IconButton>
            </Card>
          ))}
        </Stack>
      </Box>
    </Card>
  );
}
