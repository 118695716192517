import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  variationData: [],
  allData: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    getProductsSuccess(state, action) {
      state.data = action.payload.data;
    },

    getVariationProductsSuccess(state, action) {
      state.variationData = action.payload.data;
    },
    getAllProductsSuccess(state, action) {
      state.allData = action.payload.data;
    },

    clearAddData(state) {
      state.addData = {};
    },

    addOrUpdateProducts(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getAllProducts() {
  return async () => {
    try {
      const response = await get(`allproduct`);
      if (response.status) {
        dispatch(slice.actions.getAllProductsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProducts(locationId) {
  return async () => {
    try {
      const response = await get(`product`);
      if (response.status) {
        dispatch(slice.actions.getProductsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getVariationProduct() {
  return async () => {
    try {
      const response = await get(`variationProduct`);
      if (response.status) {
        dispatch(slice.actions.getVariationProductsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function searchProducts(type, value) {
  return async () => {
    try {
      const response = await get(`product/${type}/${value}`);
      if (response.status) {
        dispatch(slice.actions.getProductsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addProducts(body) {
  return async () => {
    try {
      const response = await post(`product`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProducts(body) {
  return async () => {
    try {
      const response = await put(`product/${body?.id}`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearAddDataValues() {
  return async () => {
    dispatch(slice.actions.clearAddData());
  };
}
