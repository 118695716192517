import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Tab, Tabs, TextField, Typography, Input, Tooltip, Autocomplete } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButtonAnimate } from '../animate';
import { ImageUploadAndPreviewButton } from '../../utils/common';

const fieldLimits = {
  seoId: 255,
  slug: 255,
  type: 60,
  title: 60,
  description: 160,
  metaTitle: 60,
  metaDescription: 160,
  metaKeywords: 255,
  ogTitle: 60,
  ogDescription: 160,
  ogImage: 555,
  twitterTitle: 60,
  twitterDescription: 160,
  twitterImage: 555,
  canonicalUrl: 255,
  robots: 255,
  schemaMarkup: 5000,
  viewport: 255,
};

const robotsOptions = [
  { label: 'No Index, No Follow', value: 'noindex, nofollow' },
  { label: 'Index, Follow', value: 'index, follow' },
  { label: 'No Index, Follow', value: 'noindex, follow' },
  { label: 'Index, No Follow', value: 'index, nofollow' },
];

const defaultRobotsValue = 'index, follow';

const SeoForm = ({ title, selectedDataObj, setSelectedDataObj }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const updateEditingData = (key, value) => {
    if (value.length <= fieldLimits[key]) {
      setSelectedDataObj({
        ...selectedDataObj,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    if (title) updateEditingData('slug', convertToSlug(title));
  }, [title]);

  function convertToSlug(title) {
    return title
      .toLowerCase()
      .replace(/[^\w\s-/]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/--+/g, '-')
      .replace(/\/+/g, '/');
  }

  const isValidSlug = (slug) => {
    const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*-?$/;
    return slugRegex.test(slug.replace(' ', '-'));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const renderTextField = (label, key, disabled = false, isSlug = false, showOnNull = true) =>
    (selectedDataObj?.[key] || showOnNull) && (
      <Grid item xs={12} md={6}>
        {['description', 'metaDescription', 'ogDescription', 'twitterDescription'].includes(key) ? (
          <TextField
            fullWidth
            multiline
            rows={3}
            label={label}
            value={selectedDataObj?.[key] || ''}
            onChange={(e) => {
              let value = e.target?.value;
              if (isSlug) {
                value = value.replace(/\s+/g, '-').toLowerCase();
              }
              if (!isSlug || (isSlug && isValidSlug(value))) {
                updateEditingData(key, value);
              }
            }}
            onKeyPress={handleKeyPress}
            disabled={disabled}
            helperText={`${selectedDataObj?.[key]?.length || 0}/${fieldLimits[key]} characters`}
            InputProps={{
              endAdornment: (
                <Tooltip title={selectedDataObj?.[key]}>
                  <IconButtonAnimate size='small' sx={{ p: 0.5 }} onClick={() => navigator.clipboard.writeText(selectedDataObj?.[key])}>
                    <ContentCopyIcon />
                  </IconButtonAnimate>
                </Tooltip>
              ),
            }}
            variant='outlined'
          />
        ) : key === 'robots' ? (
          <Autocomplete
            onChange={(event, newValue) => {
              updateEditingData(key, newValue?.value);
            }}
            defaultValue={robotsOptions.find((option) => option.value === defaultRobotsValue)}
            options={robotsOptions}
            value={robotsOptions.find((option) => option.value === selectedDataObj[key])}
            renderInput={(params) => <TextField label='Bot Indexing Level' {...params} />}
          />
        ) : (
          <TextField
            fullWidth
            label={label}
            value={selectedDataObj?.[key] || ''}
            onChange={(e) => {
              let value = e.target?.value;
              if (isSlug) {
                value = value.replace(/\s+/g, '-').toLowerCase();
              }
              if (!isSlug || (isSlug && isValidSlug(value))) {
                updateEditingData(key, value);
              }
            }}
            disabled={disabled}
            helperText={`${selectedDataObj?.[key]?.length || 0}/${fieldLimits[key]} characters`}
            InputProps={{
              endAdornment: (
                <Tooltip title={selectedDataObj?.[key]}>
                  <IconButtonAnimate size='small' sx={{ p: 0.5 }} onClick={() => navigator.clipboard.writeText(selectedDataObj?.[key])}>
                    <ContentCopyIcon />
                  </IconButtonAnimate>
                </Tooltip>
              ),
            }}
            variant='outlined'
          />
        )}
      </Grid>
    );

  return (
    <>
      <Typography variant='subtitle1' padding='10px 0'>
        SEO Meta Data Editor
      </Typography>
      <Tabs value={tabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)}>
        <Tab label='General Information' />
        <Tab label='Meta Information' />
        <Tab label='Open Graph (OG) Information' />
        <Tab label='Twitter Information' />
        <Tab label='Other Information' />
      </Tabs>
      <Box mt={3}>
        {tabIndex === 0 && (
          <Stack spacing={3}>
            <Grid container spacing={1}>
              {renderTextField('SEO ID', 'seoId', true, false, false)}
              {renderTextField('Slug', 'slug', true, true)}
              {renderTextField('Type', 'type', true, false, false)}
              {renderTextField('Title', 'title')}
              <Grid item xs={12} md={12}>
                {renderTextField('Description', 'description')}
              </Grid>
            </Grid>
          </Stack>
        )}
        {tabIndex === 1 && (
          <Stack spacing={3}>
            <Grid container spacing={1}>
              {renderTextField('Meta Title', 'metaTitle')}
              {renderTextField('Meta Keywords', 'metaKeywords')}
              {renderTextField('Meta Description', 'metaDescription')}
            </Grid>
          </Stack>
        )}
        {tabIndex === 2 && (
          <Stack spacing={3}>
            <Grid container spacing={1}>
              {renderTextField('OG Title', 'ogTitle')}
              {renderTextField('OG Description', 'ogDescription')}

              {selectedDataObj?.ogTitle && (
                <ImageUploadAndPreviewButton
                  hideTopic={true}
                  fileName={`${selectedDataObj?.ogTitle}-og-title-image`}
                  width={1200}
                  height={630}
                  format={['png']}
                  label='OG Image'
                  keyName='ogImage'
                  folderName='seo'
                  isImageUploading={isImageUploading}
                  setIsImageUploading={setIsImageUploading}
                  updateEditingData={updateEditingData}
                  selectedDataObj={selectedDataObj}
                />
              )}
            </Grid>
          </Stack>
        )}
        {tabIndex === 3 && (
          <Stack spacing={3}>
            <Grid container spacing={1}>
              {renderTextField('Twitter Title', 'twitterTitle')}
              {renderTextField('Twitter Description', 'twitterDescription')}

              {selectedDataObj?.twitterTitle && (
                <ImageUploadAndPreviewButton
                  hideTopic={true}
                  fileName={`${selectedDataObj?.twitterTitle}-twitter-title`}
                  width={1000}
                  height={500}
                  format={['png']}
                  label='Twitter Image'
                  keyName='twitterImage'
                  folderName='seo'
                  isImageUploading={isImageUploading}
                  setIsImageUploading={setIsImageUploading}
                  updateEditingData={updateEditingData}
                  selectedDataObj={selectedDataObj}
                />
              )}
            </Grid>
          </Stack>
        )}
        {tabIndex === 4 && (
          <Stack spacing={3}>
            <Grid container spacing={1}>
              {renderTextField('Canonical URL', 'canonicalUrl')}
              {renderTextField('Robots', 'robots')}
              {renderTextField('Schema Markup', 'schemaMarkup')}
            </Grid>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default SeoForm;
