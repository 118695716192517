import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  dateRange: [],
  searchUsers: [],
  selectedUser: {},
  isLoading: false,
  conversation: {
    isLoading: true,
    selectedUser: {},
    data: [],
    time: '',
  },
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    setDateRangeForUserData(state, action) {
      state.dateRange = action.payload;
    },

    getAssetCategoriesSuccess(state, action) {
      state.data = action.payload.data;
    },

    searchUsersSuccess(state, action) {
      state.searchUsers = action.payload.data;
    },

    startLoading(state, action) {
      state.isLoading = true;
    },

    endLoading(state, action) {
      state.isLoading = false;
    },

    profileUpdatedSuccessfully(state, action) {
      state.data = action.payload.data;
    },

    messageSentSuccessfully(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    getConversationStarted(state, action) {
      state.conversation = {
        isLoading: true,
        selectedUser: action.payload.user,
        data: state.conversation.data,
        time: moment.now().toString(),
      };
    },
    getConversationSuccess(state, action) {
      state.conversation = {
        selectedUser: action.payload.user,
        isLoading: false,
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    getConversationSuccessWithId(state, action) {
      state.conversation = {
        isLoading: false,
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

export default slice.reducer;

export function setDateRangeForUserSearch(dateRange) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setDateRangeForUserData(dateRange));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function startConversationLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
  };
}

export function endConversationLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.endLoading());
  };
}

export function getChatUsers() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const response = await get(`meta/getChatUsers?dateRange=${state?.chat?.dateRange}`);
      if (response.status) {
        dispatch(slice.actions.getAssetCategoriesSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function searchMetaChatUsers(keyword) {
  return async () => {
    try {
      const response = await get(`meta/searchMetaChatUsers?keyword=${keyword}`);
      if (response.status) {
        dispatch(slice.actions.searchUsersSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateUserProfile(body) {
  return async () => {
    try {
      const response = await put(`meta/updateUser`, body);

      if (response.status) {
        dispatch(slice.actions.profileUpdatedSuccessfully(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getConversationData(userId) {
  return async () => {
    try {
      const response = await get(`meta/conversation/${userId}`);
      if (response.status) {
        dispatch(slice.actions.getConversationSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getConversationDataWithId(userId) {
  return async () => {
    try {
      const response = await get(`meta/conversation/${userId}`);
      if (response.status) {
        dispatch(slice.actions.getConversationSuccessWithId(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendTextMessage(body) {
  return async () => {
    try {
      const response = await post(`meta/sendTextMessage`, body);
      if (response.status) {
        dispatch(slice.actions.messageSentSuccessfully(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setNote(body) {
  return async () => {
    try {
      const response = await post(`meta/setNote`, body);
      if (response.status) {
        dispatch(slice.actions.messageSentSuccessfully(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendImages(body) {
  return async () => {
    try {
      const response = await post(`meta/sendImages`, body);
      if (response.status) {
        dispatch(slice.actions.messageSentSuccessfully(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
