import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  variationData: [],
  allData: [],
  callLogs: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'contactCenterRedux',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    getAllContactListSuccess(state, action) {
      state.data = action.payload.data;
    },

    getAllCallLogsSuccess(state, action) {
      state.callLogs = action.payload.data;
    },

    getVariationProductsSuccess(state, action) {
      state.variationData = action.payload.data;
    },
    getAllProductsSuccess(state, action) {
      state.allData = action.payload.data;
    },

    clearAddData(state) {
      state.addData = {};
    },

    addOrUpdateProducts(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getAllCallLogs() {
  return async () => {
    try {
      const response = await get(`/social/callCenter/logs`);
      if (response.status) {
        dispatch(slice.actions.getAllCallLogsSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getAllContactList() {
  return async () => {
    try {
      const response = await get(`/social/callCenter/getUpdatedNumbers`);
      if (response.status) {
        dispatch(slice.actions.getAllContactListSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMobileNumberStatus(body) {
  return async () => {
    try {
      const response = await put(`/social/callCenter/status`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePriorityList(body) {
  return async () => {
    try {
      const response = await put(`/social/callCenter/priority`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMobitelName(body) {
  return async () => {
    try {
      const response = await put(`/social/callCenter/updateMobitelName`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateLinkedUser(body) {
  return async () => {
    try {
      const response = await put(`/social/callCenter/updateLinkedUser`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCallLogsMarks(body) {
  return async () => {
    try {
      const response = await put(`/social/callCenter/updateCallLogsMarks`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCallLogs(body) {
  return async () => {
    try {
      const response = await put(`/social/callCenter/updateCallLogs`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateProducts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
