import React from 'react';
import Page from '../../../components/Page';
import { useParams } from 'react-router-dom';

export default function MetaDashboard() {
  const { type } = useParams();

  const getUrl = () => {
    switch (type) {
      case 'sales':
        return 'https://meta.bubblemania.lk/public/dashboard/dd7d8824-ec22-41cc-9c16-1f4a09e473e7';
      case 'employees':
        return 'https://meta.bubblemania.lk/public/dashboard/9995ab6c-1412-4f9f-9f3a-0376a9e47aee';
      case 'stockChecker':
        return 'https://meta.bubblemania.lk/public/dashboard/c2321816-8be5-44b4-ab03-167ed3f9887c';
      case 'stockDashboard':
        return 'https://meta.bubblemania.lk/public/dashboard/2ac3bb6c-e27c-421e-9d3e-9189936049b4';
    }
  };

  return (
    <Page title='Metabase Reporting'>
      <iframe src={getUrl()} style={{ width: '100%', height: '500vh' }} frameborder='0' allowTransparency></iframe>
    </Page>
  );
}
