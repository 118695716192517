import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import { Box, Container, Tab, Tabs, TextField, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import DeliveryType from './components/deliveryType';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CustomerMobileNumber } from './components/customerMobileNumber';
import { OrderInformation } from './components/orderInformation';
import { dispatch, useSelector } from '../../../redux/store';
import { getAllSaleTypes } from '../../../redux/slices/saleRedux/salesRedux';
import { getFoodCategorys } from '../../../redux/slices/stockManagementRedux/foodCategoryRedux';
import { getMenuAndVariationFoods } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { getPaymentTypes } from '../../../redux/slices/financeRedux/paymentTypeRedux';
import { Payments } from './components/payments';
import { LoadingButton } from '@mui/lab';
import { hidePOSTab, openPOSDrawer, updateUserDataAfterUpdate } from '../../../redux/slices/posRedux/posRedux';

export default function POS({ isDrawer = false }) {
  const { themeStretch } = useSettings();
  const [currentTab, setCurrentTab] = useState('delivery_type');
  const { viewPosStatus, drawerOpeningStatus } = useSelector((state) => state?.pos);
  const { loggedInUser } = useSelector((state) => state?.user);
  const [startingAmount, setStartingAmount] = useState(0);
  const { selectedLocation } = useSelector((state) => state?.location);

  const posDataDefault = {
    deliveryInfo: {},
    posCashDrawersId: loggedInUser?.posCashDrawerID,
    locationInfo: selectedLocation,
    customerInfo: {
      id: 0,
      name: '',
      phone: '94',
      birthdayMonth: 0,
      birthdayDay: 0,
    },
    orderInfo: {
      cart: [],
      specialNote: '',
      tableNumber: 1,
    },
    paymentRecords: {
      totalAmount: 0,
      payments: [],
    },
  };
  const [data, setData] = useState(posDataDefault);

  useEffect(() => {
    setData(posDataDefault);
  }, [loggedInUser?.posCashDrawerID]);

  useEffect(() => {
    setCurrentTab('delivery_type');
    setData(posDataDefault);
    if (isDrawer) {
      if (viewPosStatus) {
        dispatch(getPaymentTypes());
        dispatch(getAllSaleTypes());
        dispatch(getFoodCategorys('menu'));
        dispatch(getMenuAndVariationFoods());
        dispatch(updateUserDataAfterUpdate());
      }
    } else {
      dispatch(getPaymentTypes());
      dispatch(getAllSaleTypes());
      dispatch(getFoodCategorys('menu'));
      dispatch(getMenuAndVariationFoods());
    }
  }, [viewPosStatus]);

  useEffect(() => {
    if (drawerOpeningStatus) {
      dispatch(updateUserDataAfterUpdate());
    }
  }, [drawerOpeningStatus]);

  const onClickSound = () => {};

  const POS_TABS = [
    {
      value: 'delivery_type',
      icon: <Iconify icon={'carbon:delivery'} width={20} height={20} />,
      component: <DeliveryType data={data?.deliveryInfo} setData={setData} onClickSound={onClickSound} onChangeTab={setCurrentTab} />,
      disabled: ['delivery_type'].includes(currentTab),
    },
    {
      value: 'order_info',
      icon: <Iconify icon={'material-symbols:order-approve-outline'} width={20} height={20} />,
      component: <OrderInformation data={data} setData={setData} onClickSound={onClickSound} onChangeTab={setCurrentTab} />,
      disabled: ['delivery_type', 'order_info'].includes(currentTab),
    },
    {
      value: 'customer_info',
      icon: <Iconify icon={'carbon:customer'} width={20} height={20} />,
      component: <CustomerMobileNumber data={data?.customerInfo} setData={setData} onClickSound={onClickSound} onChangeTab={setCurrentTab} />,
      disabled: ['delivery_type', 'customer_info', 'customer_info'].includes(currentTab),
    },
    {
      value: 'payments',
      icon: <Iconify icon={'material-symbols:order-approve-outline'} width={20} height={20} />,
      component: <Payments data={data} setData={setData} onClickSound={onClickSound} onChangeTab={setCurrentTab} />,
      disabled: ['delivery_type', 'customer_info', 'order_info', 'payments'].includes(currentTab),
    },
  ];

  return (
    <Page title='POS'>
      {loggedInUser?.posCashDrawerStatus === 'open' ? (
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Tabs allowScrollButtonsMobile variant='scrollable' scrollButtons='auto' value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)} sx={{ flexGrow: 1 }}>
              {POS_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} disabled={tab.disabled} />
              ))}
            </Tabs>
            <Box sx={{ fontSize: 30, ml: 2, cursor: 'pointer' }}>
              <a
                onClick={() => {
                  dispatch(hidePOSTab());
                }}
              >
                x
              </a>
            </Box>
          </Box>
          <Box sx={{ mb: 5 }} />
          {POS_TABS.map((tab) => {
            return tab.value === currentTab && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      ) : (
        <Container
          maxWidth={themeStretch ? false : 'lg'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
          }}
        >
          <Typography variant='h5' align='center' sx={{ mb: 3 }}>
            POS Drawer Opening
          </Typography>
          <TextField
            type='number'
            label={`POS Open Value`}
            sx={{ width: '35%', mb: 2 }}
            placeholder={`Amount in LKR`}
            onChange={(event) => {
              onClickSound();
              setStartingAmount(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onClickSound();
                dispatch(
                  openPOSDrawer({
                    locationId: selectedLocation?.id,
                    startingAmount: startingAmount,
                  })
                );
              }
            }}
          />
          <LoadingButton
            onClick={() => {
              onClickSound();
              dispatch(
                openPOSDrawer({
                  locationId: selectedLocation?.id,
                  startingAmount: startingAmount,
                })
              );
            }}
            variant='contained'
            size='large'
            color={'error'}
            sx={{ width: '35%' }}
          >
            Open POS Drawer
          </LoadingButton>
        </Container>
      )}
    </Page>
  );
}
