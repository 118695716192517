import React, { useEffect, useState } from 'react';
import { Button, Container } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { capitalize, handleOpenInNewTab } from '../../../utils/common';
import { getProducts } from '../../../redux/slices/productRedux/productRedux';

export default function ProductList() {
  const pageName = 'Product List';
  const { themeStretch } = useSettings();
  const [productList, setProductList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const { data } = useSelector((state) => state?.product);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    setProductList(data?.map((value) => ({ ...value, childrenProducts: [] })));
  }, [data]);

  const columns = !productList?.[0]
    ? []
    : Object.keys(productList?.[0])
        .map((value) => {
          if (['seo', 'description'].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell }) => {
                const cellValue = cell.getValue();
                return typeof cellValue === 'object' && cellValue !== null ? JSON.stringify(cellValue) : cellValue;
              },
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  return (
    <Page title={`${pageName}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${pageName}`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Product',
              href: '',
            },
            { name: `${pageName}` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                handleOpenInNewTab(`/dashboard/products/editor/new/parent`);
              }}
            >
              New {`${pageName}`}
            </Button>
          }
        />

        <DataGridTable
          name={`${pageName} List`}
          data={productList}
          column={columns}
          onRowClick={(row) => {
            handleOpenInNewTab(`/dashboard/products/editor/${row?.original?.id}/parent`);
          }}
          isRowClickable={true}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowSelection={false}
          enableRowActions={false}
          renderRowActionItems={(value, closeMenu) => []}
        />
      </Container>
    </Page>
  );
}
