import React from 'react';
import { Card } from '@mui/material';
import SeoForm from '../../../../../components/seo/SeoForm';

export default function ProductSeoForm({ seoData, updateSeoData }) {
  return (
    <Card sx={{ p: 3 }}>
      <SeoForm title={`product/${seoData?.title || ''}`} selectedDataObj={{ ...seoData, type: 'product' }} setSelectedDataObj={updateSeoData} />
    </Card>
  );
}
