import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { ImageUploadAndPreviewButton } from '../../../utils/common';
import { addProductCategories, getProductCategories, updateProductCategories } from '../../../redux/slices/productRedux/productCategoriesRedux';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import SeoForm from '../../../components/seo/SeoForm';
import { getProductAttributesForEdit } from '../../../redux/slices/productRedux/productAttributesRedux';

export default function ProductCategories() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { data, addData } = useSelector((state) => state.productCategory);

  let origAttributesList;
  ({ data: origAttributesList } = useSelector((state) => state.productAttributes));

  const [isImageUploading, setIsImageUploading] = useState(false);
  const [attributeList, setAttributeList] = useState([]);
  const originalModelData = {
    id: '',
    priority: '',
    name: '',
    description: '',
    iconUrl: '',
    logoUrl: '',
    parentId: null,
    seoId: null,
    requiredProductAttributes: [],
  };

  const [selectedDataObj, setSelectedDataObj] = useState(originalModelData);

  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getProductAttributesForEdit());
  }, [dispatch]);

  useEffect(() => {
    if (addData?.data) {
      dispatch(getProductCategories());
      handleModelToggle(originalModelData, 'add');
    }
  }, [addData, dispatch]);
  useEffect(() => {
    if (origAttributesList) {
      setAttributeList(origAttributesList);
    }
  }, [origAttributesList]);

  const handleModelToggle = (modelData, type) => {
    setIsModelOpen(!isModelOpen);
    setIsAdd(type === 'add');
    setSelectedDataObj(
      type === 'add'
        ? originalModelData
        : {
            ...modelData,
            requiredProductAttributes:
              modelData?.requiredProductAttributes?.map((value) => {
                return {
                  label: attributeList?.find((attribute) => attribute?.id === value?.attributeId)?.name || 'No Label',
                  value: value?.attributeId,
                };
              }) || [],
          }
    );
  };

  const updateEditingData = (key, value) => {
    setSelectedDataObj((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    if (!selectedDataObj.name) {
      toast.error('Name is required!');
    } else if (!selectedDataObj.description) {
      toast.error('Description is required!');
    } else {
      dispatch(isAdd ? addProductCategories([selectedDataObj]) : updateProductCategories(selectedDataObj));
    }
  };

  const buildCategoryTree = (categories) => {
    const categoryMap = {};
    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });
    const nestedCategories = [];
    categories.forEach((category) => {
      if (category.parentId === null) {
        nestedCategories.push(categoryMap[category.id]);
      } else {
        categoryMap[category.parentId].children.push(categoryMap[category.id]);
      }
    });
    return nestedCategories;
  };

  const mapCategories = (categories) => {
    return categories.map((category) => ({
      id: category.id.toString(),
      label: category.name,
      children: category.children && category.children.length > 0 ? mapCategories(category.children) : [],
    }));
  };
  const mappedCategories = mapCategories(buildCategoryTree(data));

  return (
    <Page title='Product Categories'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading='Product Categories' links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Product', href: '' }, { name: 'Product Categories' }]} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Box sx={{ minHeight: 352, minWidth: 250 }}>
              <Button style={{ width: '100%' }} variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => handleModelToggle(selectedDataObj, 'add')}>
                New Category
              </Button>
              <br />
              <br />
              <RichTreeView
                items={mappedCategories}
                onSelectedItemsChange={(event, id) => {
                  const selectedCategory = data.find((item) => item.id === Number(id));
                  handleModelToggle(selectedCategory, 'edit');
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Typography variant='subtitle1' gutterBottom>
                  Product Category Information
                </Typography>
                {selectedDataObj?.id && <TextField fullWidth type={'number'} label='Id' value={selectedDataObj.id} onChange={(e) => updateEditingData('id', e.target.value)} disabled={true} />}
                <TextField fullWidth type={'number'} label='Priority' value={selectedDataObj.priority} onChange={(e) => updateEditingData('priority', e.target.value)} />
                <TextField fullWidth label='Name' value={selectedDataObj.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                <TextField fullWidth label='Description' value={selectedDataObj.description} onChange={(e) => updateEditingData('description', e.target.value)} />
                <Autocomplete
                  multiple
                  value={selectedDataObj.requiredProductAttributes}
                  onChange={(event, newValue) => {
                    updateEditingData('requiredProductAttributes', newValue);
                  }}
                  options={attributeList.map((attribute) => ({
                    label: attribute.name,
                    value: attribute.id,
                  }))}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) =>
                    selectedDataObj.requiredProductAttributes?.filter((value) => {
                      return value?.value === option.value;
                    })?.length > 0
                  }
                  renderInput={(params) => <TextField {...params} label='Select Required Attribute' />}
                />

                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <Typography variant='h6' gutterBottom>
                      Select Parent Category
                    </Typography>
                    {selectedDataObj?.parentId === null ? (
                      <p style={{ color: 'grey' }}>(No Parent Selected)</p>
                    ) : (
                      <a
                        onClick={() => {
                          updateEditingData('parentId', null);
                        }}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        <p>(Remove - {data?.find((value) => value?.id?.toString() === selectedDataObj?.parentId?.toString())?.name})</p>
                      </a>
                    )}
                    <RichTreeView
                      items={mappedCategories?.filter((value) => value?.id !== selectedDataObj?.id?.toString())}
                      selectedItems={selectedDataObj?.parentId?.toString() || null}
                      onSelectedItemsChange={(event, id) => {
                        updateEditingData('parentId', Number(id));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {selectedDataObj?.name && (
                      <ImageUploadAndPreviewButton
                        fileName={`${selectedDataObj?.name}-icon`}
                        width={500}
                        height={500}
                        format={['png', 'avif', 'jpeg']}
                        label='Icon'
                        keyName='iconUrl'
                        folderName='productCategories'
                        isImageUploading={isImageUploading}
                        setIsImageUploading={setIsImageUploading}
                        updateEditingData={updateEditingData}
                        selectedDataObj={selectedDataObj}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {selectedDataObj?.name && (
                      <ImageUploadAndPreviewButton
                        fileName={`${selectedDataObj?.name}-logo`}
                        width={1000}
                        height={1000}
                        format={['png', 'avif', 'jpeg']}
                        label='Logo'
                        keyName='logoUrl'
                        folderName='productCategories'
                        isImageUploading={isImageUploading}
                        setIsImageUploading={setIsImageUploading}
                        updateEditingData={updateEditingData}
                        selectedDataObj={selectedDataObj}
                      />
                    )}
                  </Grid>
                </Grid>
                <br />
                <hr />
                <SeoForm
                  title={`product-categories/${selectedDataObj?.name}`}
                  selectedDataObj={{ ...selectedDataObj.seo, type: 'product-categories' }}
                  setSelectedDataObj={(value) => setSelectedDataObj((prev) => ({ ...prev, seo: value }))}
                />
                <LoadingButton fullWidth variant='contained' onClick={handleSubmit}>
                  {isAdd ? 'Add Product Category' : 'Save Product Category'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
