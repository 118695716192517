import React, { useEffect, useState } from 'react';
import { Container, Button, Box, ButtonGroup, Tooltip } from '@mui/material';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { connectMetaUser, getAllMetaPages, manageSubscription } from '../../../../redux/slices/socialRedux/metaRedux';
import { dispatch, useSelector } from '../../../../redux/store';
import DataGridTable from '../../../../components/table/DataGridTable';
import { capitalize } from '../../../../utils/common';
import Iconify from '../../../../components/Iconify';
import { Cancel } from '@material-ui/icons';
import { CheckCircle } from '@mui/icons-material';

export default function MetaConnect() {
  const pageName = 'Meta Connect';
  const { themeStretch } = useSettings();
  const [pageListData, setPageListData] = useState([]);
  const { pageData, metaPageConnectSuccess, addData } = useSelector((state) => state.meta);
  const [rowSelection, setRowSelection] = useState(false);

  useEffect(() => {
    dispatch(getAllMetaPages());
  }, []);

  useEffect(() => {
    if (metaPageConnectSuccess || addData?.time) {
      dispatch(getAllMetaPages());
    }
  }, [metaPageConnectSuccess, addData]);

  useEffect(() => {
    setPageListData(pageData);
  }, [pageData, metaPageConnectSuccess]);

  const loadFacebookSDK = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1572140917059621',
        cookie: true,
        xfbml: true,
        version: 'v21.0',
      });
    };

    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  const handleLogin = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          dispatch(connectMetaUser(response.authResponse.accessToken));
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope:
          'ads_management,ads_read,catalog_management,commerce_account_manage_orders,commerce_account_read_orders,email,public_profile,page_events,whatsapp_business_messaging,whatsapp_business_management,pages_read_engagement,pages_read_user_content,pages_show_list,pages_manage_metadata,pages_manage_posts,pages_messaging,pages_manage_ads',
      }
    );
  };

  useEffect(() => {
    loadFacebookSDK();
  }, []);

  const columns = !pageListData?.[0]
    ? []
    : Object.keys(pageListData[0])
        .map((key) => {
          if (key === 'authToken') return null;

          const commonProperties = {
            accessorKey: key,
            header: capitalize(key),
          };

          if (key === 'id') {
            return {
              ...commonProperties,
              header: 'Webhook Connection',
              Cell: ({ row }) => {
                return (
                  <ButtonGroup variant='text' aria-label='webhook button group'>
                    {row?.original?.type !== 'Instagram' ? (
                      row?.original?.isSubscribed ? (
                        <Tooltip title='Disconnect from Webhook'>
                          <Button
                            color='error'
                            startIcon={<Cancel />}
                            onClick={() => {
                              dispatch(manageSubscription(row?.original));
                            }}
                          >
                            Disconnect
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Connect to Webhook'>
                          <Button
                            color='success'
                            startIcon={<CheckCircle />}
                            onClick={() => {
                              dispatch(manageSubscription(row?.original));
                            }}
                          >
                            Connect
                          </Button>
                        </Tooltip>
                      )
                    ) : (
                      <b>Always Connected</b>
                    )}
                  </ButtonGroup>
                );
              },
            };
          }

          if (key === 'type') {
            return {
              ...commonProperties,
              Cell: ({ row }) => {
                const typeValue = row?.original?.[key];
                const styles = {
                  Facebook: { color: '#1877F2', label: 'Facebook', icon: 'ic:twotone-facebook' },
                  Instagram: { color: '#E4405F', label: 'Instagram', icon: 'mdi:instagram' },
                  Whatsapp: { color: '#25D366', label: 'Whatsapp', icon: 'ic:twotone-whatsapp' },
                };
                const { color, label, icon } = styles[typeValue] || {};
                return (
                  <span style={{ color, fontWeight: 900 }}>
                    {icon && <Iconify icon={icon} />} {label || typeValue}
                  </span>
                );
              },
            };
          }

          if (key === 'status') {
            return {
              ...commonProperties,
              Cell: ({ row }) => {
                const isActive = row?.original?.[key] === 'active';
                return <span style={{ color: isActive ? '#00742a' : '#b80020', fontWeight: 900 }}>{isActive ? 'Active' : 'In-Active'}</span>;
              },
            };
          }
          return commonProperties;
        })
        .filter(Boolean);

  return (
    <Page title={pageName}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={pageName}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Call Center', href: '' }, { name: pageName }]}
          action={
            <Box display='flex' gap={2}>
              <Button
                variant='contained'
                startIcon={<Iconify icon='line-md:facebook' />}
                onClick={handleLogin}
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#1877F2',
                  '&:hover': { backgroundColor: '#155BBD' },
                }}
              >
                Connect with Facebook
              </Button>
              <Button
                variant='contained'
                startIcon={<Iconify icon='line-md:instagram' />}
                href={`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=3318172531652384&redirect_uri=${process.env?.REACT_APP_REDIRECT_URL}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`}
                sx={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  backgroundColor: '#E4405F',
                  '&:hover': { backgroundColor: '#D73552' },
                }}
              >
                Connect with Instagram
              </Button>
            </Box>
          }
        />
        <Box mt={3}>
          <DataGridTable
            name='metaData'
            data={pageListData}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            isRowClickable={true}
            onRowClick={(value) => {
              manageModel(value?.original, 'edit');
            }}
            renderRowActionItems={() => []}
          />
        </Box>
      </Container>
    </Page>
  );
}
