import React, { useState, useMemo, useEffect } from 'react';
import { Autocomplete, TextField, Dialog, DialogTitle, List, ListItem, ListItemText, Breadcrumbs, Typography, Divider, Box, ListItemIcon, Slide } from '@mui/material';
import { Folder, ArrowForward, Category } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';

const buildCategoryTree = (categories) => {
  const categoryMap = {};
  const tree = [];

  categories.forEach((category) => {
    categoryMap[category.id] = { ...category, children: [] };
  });

  categories.forEach((category) => {
    if (category.parentId === null) {
      tree.push(categoryMap[category.id]);
    } else if (categoryMap[category.parentId]) {
      categoryMap[category.parentId].children.push(categoryMap[category.id]);
    }
  });

  return { tree, categoryMap };
};

const findCategoryPathById = (categoryMap, categoryId) => {
  let path = [];
  let currentCategory = categoryMap[categoryId];
  while (currentCategory) {
    path.unshift(currentCategory);
    currentCategory = categoryMap[currentCategory.parentId];
  }
  return path;
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  transition: 'background-color 0.2s ease-in-out',
}));

const BreadcrumbWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const CategoryListWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[5],
    maxWidth: '0px',
    width: '0%',
    animation: 'dialogAnimation 0.4s ease forwards',
  },
  '@keyframes dialogAnimation': {
    '0%': {
      maxWidth: '0px',
      width: '0%',
    },
    '100%': {
      maxWidth: '600px',
      width: '100%',
    },
  },
}));

export default function CategorySelector({ value, onCategorySelect }) {
  const { data: productCategoryList } = useSelector((state) => state.productCategory);

  const { tree: categoryTree, categoryMap } = useMemo(() => buildCategoryTree(productCategoryList), [productCategoryList]);

  const [selectedCategoryPath, setSelectedCategoryPath] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(categoryTree);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);

  useEffect(() => {
    if (value && categoryMap[value]) {
      const path = findCategoryPathById(categoryMap, value);
      setSelectedCategoryPath(path);
    } else {
      setSelectedCategoryPath([]);
    }
  }, [value, categoryMap]);

  const buildFullPathString = (path) => path.map((category) => category.name).join(' > ');

  const handleCategorySelectInModal = (category) => {
    if (!category.children || category.children.length === 0) {
      const fullPath = [...breadcrumbPath, category];
      setSelectedCategoryPath(fullPath);
      onCategorySelect(category.id);
      setModalOpen(false);
    } else {
      setBreadcrumbPath([...breadcrumbPath, category]);
      setCurrentCategory(category.children);
    }
  };

  const handleBreadcrumbClick = (index) => {
    if (index === breadcrumbPath.length) return;
    const newPath = breadcrumbPath.slice(0, index);
    setCurrentCategory(index === 0 ? categoryTree : breadcrumbPath[index - 1].children);
    setBreadcrumbPath(newPath);
  };

  const handleAllCategoriesClick = () => {
    setBreadcrumbPath([]);
    setCurrentCategory(categoryTree);
  };

  return (
    <div>
      <Autocomplete
        options={[]}
        value={buildFullPathString(selectedCategoryPath)}
        getOptionLabel={() => buildFullPathString(selectedCategoryPath)}
        renderInput={(params) => <TextField {...params} label='Select Category' variant='outlined' onClick={() => setModalOpen(true)} value={buildFullPathString(selectedCategoryPath)} />}
        readOnly
      />

      <StyledDialog open={modalOpen} TransitionComponent={Slide} onClose={() => setModalOpen(false)} fullWidth maxWidth='sm'>
        <DialogTitle>Select Category</DialogTitle>

        <BreadcrumbWrapper>
          <Breadcrumbs aria-label='breadcrumb'>
            <Typography color='inherit' style={{ cursor: 'pointer' }} onClick={handleAllCategoriesClick}>
              All Categories
            </Typography>
            {breadcrumbPath.map((category, index) => (
              <Typography key={category.id} color='inherit' style={{ cursor: 'pointer' }} onClick={() => handleBreadcrumbClick(index + 1)}>
                {category.name}
              </Typography>
            ))}
          </Breadcrumbs>
        </BreadcrumbWrapper>

        <Divider />

        <CategoryListWrapper>
          <List>
            {currentCategory.map((category) => (
              <StyledListItem button key={category.id} onClick={() => handleCategorySelectInModal(category)}>
                <ListItemIcon>{category.children && category.children.length > 0 ? <Folder /> : <Category />}</ListItemIcon>
                <ListItemText primary={category.name} />
                {category.children && category.children.length > 0 && <ArrowForward />}
              </StyledListItem>
            ))}
          </List>
        </CategoryListWrapper>
      </StyledDialog>
    </div>
  );
}
