import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, isHorizontal = true, sx }) {
  const logo = (
    <Box sx={{ ...sx }}>
      {isHorizontal ? (
        <img width={sx.width} height={sx.height} src={`https://res.cloudinary.com/cdi-buyzone-lk/image/upload/h_${sx.height}/suite/h-logo.avif`} />
      ) : (
        <img width={sx.width} height={sx.height} src={`https://res.cloudinary.com/cdi-buyzone-lk/image/upload/h_${sx.height}/suite/SquareLogo.avif`} style={{ borderRadius: '20%' }} />
      )}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to='/'>{logo}</RouterLink>;
}
