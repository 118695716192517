// https://www.veryicon.com/icons/miscellaneous/indata/walking-1.html

import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import DataFilter from './components/filter';
import { useDispatch } from '../../../redux/store';
import TotalSalesIcon from './components/icons/totalSalesIcon';
import DashboardStatBox from './components/DashboardStatBox';
import {
  getHourlySalesDateBased,
  getHourlySalesHourBased,
  getItemWiseSales,
  getPaymentTypes,
  getPickMeOrderCount,
  getTotalNumberOfSales,
  getTotalSaleAmount,
  getUberOrderCount,
  getWalkInCustomerOrderCount,
} from './components/dashboardCalculations';
import { fCurrency } from '../../../utils/formatNumber';
import SalesCountIcon from './components/icons/salesCountIcon';
import WalkingCustomersIcon from './components/icons/walkingCustomersIcon';
import DeliveryIcon from './components/icons/deliveryIcon';
import LineChartComponent from './components/LineChart';
import PieChartComponent from './components/PieChart';
import SalesTargetViewer from './components/SalesTargetViewer';
import BarChartComponent from './components/BarChart';
import { accessVerify } from '../../../utils/common';

export default function HomeDashboard() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const [data, setData] = useState([]);

  return (
    <Page title='General: Banking'>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <DataFilter data={data} setData={setData} />

          <Grid item xs={12}>
            {accessVerify('DASHBOARD_ANALYTICS_SALES_TARGET') && <SalesTargetViewer title='Sales Target' value={fCurrency(getTotalSaleAmount(data))} icon={<TotalSalesIcon />} shortenNumber={false} />}
          </Grid>

          <Grid item xs={12} md={6}>
            {accessVerify('DASHBOARD_ANALYTICS_TOTAL_SALE_AMOUNT') && (
              <DashboardStatBox title='Total Sale Amount' value={fCurrency(getTotalSaleAmount(data))} icon={<TotalSalesIcon />} shortenNumber={false} />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='KOT Count' value={getTotalNumberOfSales(data)} icon={<SalesCountIcon />} />}
          </Grid>
          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_WALK_IN_COUNT') && <DashboardStatBox title='Walk-in Count' value={getWalkInCustomerOrderCount(data)} icon={<WalkingCustomersIcon />} />}
          </Grid>
          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_PICKME_COUNT') && <DashboardStatBox title='Pickme Count' value={getPickMeOrderCount(data)} icon={<DeliveryIcon />} />}
          </Grid>
          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_UBER_COUNT') && <DashboardStatBox title='Uber Count' value={getUberOrderCount(data)} icon={<DeliveryIcon />} />}
          </Grid>
          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_PAYMENT_TYPES') && <PieChartComponent title='Payment Types' value={getPaymentTypes(data)} />}
          </Grid>
          <Grid item xs={12} md={8}>
            {accessVerify('DASHBOARD_ANALYTICS_HOURLY_SALES_AMOUNT_GRAPH') && <LineChartComponent title='Hourly Sales Amounts (Date Based)' value={getHourlySalesDateBased(data)} />}
          </Grid>
          <Grid item xs={12} md={8}>
            {accessVerify('DASHBOARD_ANALYTICS_HOURLY_SALES_AMOUNT_GRAPH') && <LineChartComponent title='Hourly Sales Amounts (Hour Based)' value={getHourlySalesHourBased(data)} />}
          </Grid>
          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_ITEM_WISE_SALE_COUNT_CHARTS') && <PieChartComponent title='Item Count Wise Sales' value={getItemWiseSales(data, 'pie')} />}
          </Grid>
          <Grid item xs={12} md={8}>
            {accessVerify('DASHBOARD_ANALYTICS_ITEM_WISE_SALE_COUNT_CHARTS') && <BarChartComponent title='Item Count Wise Sales' value={getItemWiseSales(data, 'bar')} />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
