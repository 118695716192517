import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { capitalize, safeJSONParse } from '../../../utils/common';
import SeoForm from '../../../components/seo/SeoForm';
import { addProductTag, getProductTag, updateProductTag } from '../../../redux/slices/productRedux/productTagsRedux';

export default function ProductTags() {
  const pageName = 'Product Tags';
  const pageNameSingular = 'Product Tags';
  const { themeStretch } = useSettings();
  const dataModel = {
    id: '',
    name: '',
    description: '',
    seo: [],
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [variationList, setVariationList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const { data, addData } = useSelector((state) => state?.productTags);

  useEffect(() => {
    dispatch(getProductTag());
  }, []);

  useEffect(() => {
    setVariationList(data?.map((value) => ({ ...value, seo: safeJSONParse(value?.seo) })));
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getProductTag());
    }
  }, [addData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !variationList?.[0]
    ? []
    : Object.keys(variationList?.[0])
        .map((value) => {
          if (['seo'].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const handleSubmit = () => {
    if (selectedDataObj.name.length === 0) {
      toast.error('Name is required!');
    } else if (selectedDataObj.description.length === 0) {
      toast.error('Description is required!');
    } else {
      dispatch(isAdd ? addProductTag([selectedDataObj]) : updateProductTag(selectedDataObj));
    }
  };

  return (
    <Page title={`${pageName}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${pageName}`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Product',
              href: '',
            },
            { name: `${pageName}` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New {`${pageName}`}
            </Button>
          }
        />

        <DataGridTable
          name={`${pageName} List`}
          data={variationList}
          column={columns}
          onRowClick={(row) => {
            manageModel(row?.original, 'edit');
          }}
          isRowClickable={true}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowSelection={false}
          enableRowActions={false}
          renderRowActionItems={(value, closeMenu) => []}
        />

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>
              {isAdd ? 'Add' : 'Edit'} {pageNameSingular}
            </h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    {`${pageNameSingular}`} Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', capitalize(e.target.value))} />
                    <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', capitalize(e.target.value))} />
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <SeoForm
                    title={selectedDataObj?.name}
                    selectedDataObj={{ ...selectedDataObj?.seo, type: 'product-tag' }}
                    setSelectedDataObj={(value) => {
                      setSelectedDataObj((currentValue) => {
                        return {
                          ...currentValue,
                          seo: value,
                        };
                      });
                    }}
                  />
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                      {isAdd ? `Add ${pageNameSingular}` : `Save ${pageNameSingular}`}
                    </LoadingButton>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
