import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Button, Tabs, Tab, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import { dispatch } from '../../../../redux/store';
import { getProductCategories } from '../../../../redux/slices/productRedux/productCategoriesRedux';
import { getProductAttributesForEdit } from '../../../../redux/slices/productRedux/productAttributesRedux';
import ProductInformationForm from './sub/productInformationForm';
import ProductSeoForm from './sub/productSeoForm';
import ProductImages from './sub/productImages';
import ProductAttributes from './sub/productAttributes';
import ProductVariation from './sub/productVariation';
import { addProducts, clearAddDataValues, searchProducts, updateProducts } from '../../../../redux/slices/productRedux/productRedux';
import { getRequiredProductAttributesFromCategoryList } from '../../../../utils/common';

export default function ProductEditor() {
  const { productId, parentId } = useParams();
  const dataModel = {
    id: productId,
    name: '',
    shortDescription: '',
    description: '',
    lowestSellingPrice: '',
    parentId: parentId === 'parent' ? 'parent' : Number(parentId),
    categoryId: null,
    urlId: null,
    priority: null,
    iconUrl: null,
    logoUrl: null,
    seoId: null,
    seo: {},
    images: [],
    childrenProducts: [],
    productAttributesOptions: [],
    isAvailableOnWeb: false,
    hasVariations: false,
  };

  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [tabIndex, setTabIndex] = useState(0);
  const { themeStretch } = useSettings();
  const isAdd = productId === 'new';
  const productOrVariation = parentId === 'parent' ? 'Product' : 'Variation';
  const pageName = isAdd ? `Add ${productOrVariation}` : `${productOrVariation} Update`;

  const productCategoryList = useSelector((state) => state.productCategory?.data);
  const requiredAttributes = getRequiredProductAttributesFromCategoryList(productCategoryList, selectedDataObj?.categoryId);
  const productData = useSelector((state) => state.product?.data);
  const dataAboutProductUpdate = useSelector((state) => state.product?.addData?.data);

  useEffect(() => {
    dispatch(getProductCategories());
    dispatch(getProductAttributesForEdit());
  }, []);

  useEffect(() => {
    const insertId = dataAboutProductUpdate?.data?.insertId;
    if (insertId) {
      dispatch(clearAddDataValues());
      setTimeout(() => {
        window.location.href = `/dashboard/products/editor/${insertId}/${parentId}`;
      }, 1000);
    } else {
      if (productId !== 'new') dispatch(searchProducts('id', productId));
    }
  }, [dataAboutProductUpdate]);

  useEffect(() => {
    if (!isAdd) {
      if (productId !== 'new') dispatch(searchProducts('id', productId));
    }
  }, [productId]);

  useEffect(() => {
    if (!isAdd && productData?.length) {
      const tempData = productData[0];
      setSelectedDataObj({
        ...tempData,
        hasVariations: tempData?.childrenProducts?.length > 0,
        parentId: parentId === 'parent' ? 'parent' : Number(parentId),
        productAttributesOptions: tempData?.productAttributesOptions?.map((item) => ({
          ...item,
          label: item?.productAttributeOptions?.name,
          attributeId: item?.productAttributeOptions?.productAttribute?.id,
        })),
      });
    }
  }, [productData, isAdd]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const tabItems = [
    {
      label: 'All',
      content: (
        <>
          <Grid item xs={8}>
            <ProductInformationForm selectedDataObj={selectedDataObj} updateEditingData={updateEditingData} productCategoryList={productCategoryList} />
          </Grid>
          <Grid item xs={4}>
            <ProductImages images={selectedDataObj?.images} setSelectedDataObj={setSelectedDataObj} productId={selectedDataObj.id} />
          </Grid>
          <Grid item xs={4}>
            {selectedDataObj?.categoryId &&
              (selectedDataObj?.hasVariations ? (
                parentId === 'parent' && productId !== 'new' && <ProductVariation selectedData={selectedDataObj} setSelectedDataObj={setSelectedDataObj} />
              ) : (
                <ProductAttributes selectedData={selectedDataObj} setSelectedDataObj={setSelectedDataObj} />
              ))}
          </Grid>
          <Grid item xs={8}>
            <ProductSeoForm seoData={selectedDataObj?.seo} updateSeoData={(value) => updateEditingData('seo', value)} />
          </Grid>
        </>
      ),
    },
    {
      label: 'Product Information',
      content: (
        <Grid item xs={12}>
          <ProductInformationForm selectedDataObj={selectedDataObj} updateEditingData={updateEditingData} productCategoryList={productCategoryList} />
        </Grid>
      ),
    },
    {
      label: 'Images',
      content: (
        <Grid item xs={12}>
          <ProductImages images={selectedDataObj?.images} setSelectedDataObj={setSelectedDataObj} productId={selectedDataObj.id} />
        </Grid>
      ),
    },
    selectedDataObj?.categoryId &&
      !selectedDataObj?.hasVariations && {
        label: 'Attributes',
        content: (
          <Grid item xs={12}>
            <ProductAttributes selectedData={selectedDataObj} setSelectedDataObj={setSelectedDataObj} />
          </Grid>
        ),
      },
    selectedDataObj?.categoryId &&
      selectedDataObj?.hasVariations && {
        label: 'Variations',
        content: (
          <Grid item xs={12}>
            <ProductVariation selectedData={selectedDataObj} setSelectedDataObj={setSelectedDataObj} />
          </Grid>
        ),
      },
    {
      label: 'SEO',
      content: (
        <Grid item xs={12}>
          <ProductSeoForm seoData={selectedDataObj?.seo} updateSeoData={(value) => updateEditingData('seo', value)} />
        </Grid>
      ),
    },
  ].filter(Boolean);

  return (
    <Page title={`${pageName}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${pageName}`}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Product', href: '' }, { name: `${pageName}` }]}
          action={
            <Button
              variant='contained'
              onClick={() => {
                if (isAdd) {
                  dispatch(addProducts({ ...selectedDataObj, requiredAttributes: requiredAttributes }));
                } else {
                  dispatch(updateProducts({ ...selectedDataObj, requiredAttributes: requiredAttributes }));
                }
              }}
            >
              Save
            </Button>
          }
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label='product editor tabs'>
            {tabItems.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
        </Box>

        <Grid container spacing={2}>
          {tabItems[tabIndex]?.content}
        </Grid>
      </Container>
    </Page>
  );
}
