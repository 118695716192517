import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography, Chip, Switch, FormControlLabel } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize } from '../../../utils/common';
import { addProductAttributes, getProductAttributesForEdit, updateProductAttributes } from '../../../redux/slices/productRedux/productAttributesRedux';
import AddIcon from '@mui/icons-material/Add';
import OptionForm from './sub/OptionForm';
import { COLOR_CODE } from '../../../utils/constants';
import Iconify from '../../../components/Iconify';

export default function ProductAttributes() {
  const { themeStretch } = useSettings();
  const pageName = 'Product Attributes';
  const pageNameSingular = 'Product Attribute';
  const dataModel = {
    id: '',
    name: '',
    description: '',
    preUnit: '',
    postUnit: '',
    isEffectOnImages: false,
    isEffectOnPricing: false,
    isEffectOnDescription: false,
    options: [],
  };
  const optionDataModel = {
    id: null,
    name: '',
    description: '',
    iconUrl: '',
    colorCode: '',
    used: false,
  };

  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [variationList, setVariationList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const { data, addData } = useSelector((state) => state?.productAttributes);

  const [optionDialogOpen, setOptionDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    dispatch(getProductAttributesForEdit());
  }, []);

  useEffect(() => {
    setVariationList(
      data?.map((value) => ({
        ...value,
        options: value?.options
          ?.filter((filteringData) => filteringData?.name)
          ?.map((value) => {
            return {
              ...value,
              used: value?.product_ProductAttributesOptions?.length > 0,
            };
          }),
      }))
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getProductAttributesForEdit());
    }
  }, [addData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const handleOptionEdit = (index) => {
    const option = selectedDataObj.options[index];
    if (!option?.used || accessVerify('ALLOWED_TO_EDIT_USED_ATTRIBUTES')) {
      setSelectedOption(option);
      setOptionDialogOpen(true);
    } else {
      toast.error('This attribute is already used in products. So you are not allowed to update this!');
    }
  };

  const handleOptionRemove = (index) => {
    if (!selectedDataObj.options[index]?.used || accessVerify('ALLOWED_TO_EDIT_USED_ATTRIBUTES')) {
      const updatedOptions = selectedDataObj.options.filter((_, i) => i !== index);
      updateEditingData('options', updatedOptions);
    } else {
      toast.error('This attribute is already used in products. So you are not allowed to remove this!');
    }
  };

  const handleAddOption = () => {
    setSelectedOption(optionDataModel);
    setOptionDialogOpen(true);
  };

  const saveOption = (optionData) => {
    const updatedOptions = selectedDataObj.options.map((opt) => (opt.id === optionData.id ? optionData : opt));
    if (!optionData.id) {
      updatedOptions.push({
        ...optionData,
        id: 'RemovableID_' + new Date().getTime(),
      });
    }
    updateEditingData('options', updatedOptions);
  };

  const handleSubmit = () => {
    const errors = [];
    if (!selectedDataObj.name) errors.push('Name is required.');
    if (errors.length > 0) {
      toast.error(errors.join(' '));
      return;
    }

    const updatedSelectedDataObj = {
      ...selectedDataObj,
      options: selectedDataObj?.options?.map((optionValue) => {
        return { ...optionValue, id: optionValue?.id?.toString()?.includes('RemovableID_') ? null : optionValue?.id };
      }),
    };
    if (isAdd) {
      dispatch(addProductAttributes(updatedSelectedDataObj));
    } else {
      dispatch(updateProductAttributes(updatedSelectedDataObj));
    }
  };

  const columns = !variationList?.[0]
    ? []
    : Object.keys(variationList?.[0])
        .map((value) => {
          if (value === 'options') {
            return {};
          } else if (['preUnit', 'postUnit'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return row?.original?.[value] ? row?.original?.[value] : '-';
              },
            };
          } else if (['isEffectOnImages', 'isEffectOnPricing', 'isEffectOnDescription'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const hasEffect = row?.original?.[value];
                return (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    {hasEffect ? (
                      <Iconify style={{ color: COLOR_CODE.green }} icon={'gg:check-o'} width={20} height={20} />
                    ) : (
                      <Iconify style={{ color: COLOR_CODE.red }} icon={'ant-design:stop-outlined'} width={20} height={20} />
                    )}
                  </Box>
                );
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  return (
    <Page title={pageName}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading={pageName} links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: pageName }]} />
        <DataGridTable
          name={`${pageName} List`}
          data={variationList}
          column={columns}
          onRowClick={(row) => {
            manageModel(row?.original, 'edit');
          }}
          isRowClickable={true}
          isLoading={false}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enableRowSelection={false}
          enableRowActions={false}
          renderRowActionItems={(value, closeMenu) => []}
        />
        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>
              {isAdd ? 'Add' : 'Edit'} {pageNameSingular}
            </h1>
          </Box>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={8}>
              <Stack spacing={2}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <TextField fullWidth label='Description' multiline rows={4} value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField fullWidth label='Pre Unit' value={selectedDataObj?.preUnit} onChange={(e) => updateEditingData('preUnit', e.target.value)} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField fullWidth label='Post Unit' value={selectedDataObj?.postUnit} onChange={(e) => updateEditingData('postUnit', e.target.value)} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          control={<Switch checked={selectedDataObj?.isEffectOnImages} onChange={(e) => updateEditingData('isEffectOnImages', e.target.checked)} />}
                          label='Effect on Images'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          control={<Switch checked={selectedDataObj?.isEffectOnPricing} onChange={(e) => updateEditingData('isEffectOnPricing', e.target.checked)} />}
                          label='Effect on Pricing'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          control={<Switch checked={selectedDataObj?.isEffectOnDescription} onChange={(e) => updateEditingData('isEffectOnDescription', e.target.checked)} />}
                          label='Effect on Description'
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '10px 0',
                    }}
                  >
                    <Typography variant='subtitle1'>Options</Typography>
                    <Button variant='contained' onClick={handleAddOption} startIcon={<AddIcon />}>
                      Add Option
                    </Button>
                  </Box>
                  {selectedDataObj?.options && (
                    <Stack direction='row' spacing={1} alignItems='center'>
                      {selectedDataObj?.options?.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={`${selectedDataObj?.preUnit || ''} ${option?.name} ${selectedDataObj?.postUnit || ''}`}
                          onClick={() => handleOptionEdit(index)}
                          onDelete={() => handleOptionRemove(index)}
                          sx={{ marginBottom: 1 }}
                        />
                      ))}
                    </Stack>
                  )}
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                      {isAdd ? `Add ${pageNameSingular}` : `Save ${pageNameSingular}`}
                    </LoadingButton>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>

      <OptionForm open={optionDialogOpen} onClose={() => setOptionDialogOpen(false)} onSave={saveOption} option={selectedOption} optionModel={optionDataModel} />
    </Page>
  );
}
