import React, { useState } from 'react';
import { Button, Card, Dialog, DialogActions, DialogTitle, ImageList, ImageListItem, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { uploadImageToCloudinary } from '../../../../../utils/common';

export default function ProductImages({ images, setSelectedDataObj, productId }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [openActionMenu, setOpenActionMenu] = useState(false);

  const handleImageUpload = () => {
    uploadImageToCloudinary('product', 800, 1000, 'product-photo-gallery', ['avif', 'png', 'jpg', 'webp'])
      .then((data) => {
        const uploadedImageUrl = data?.data?.publicUrl;
        if (uploadedImageUrl) {
          setSelectedDataObj((prevState) => {
            return {
              ...prevState,
              images: [].concat(
                [
                  {
                    id: images.length + 1,
                    isActive: true,
                    isPrimary: images.length === 0,
                    url: uploadedImageUrl,
                    productId: productId,
                  },
                ],
                prevState.images
              ),
            };
          });
        }
      })
      .catch((err) => {
        if (err !== 'close') {
          toast.error('Unexpected error occurred!');
        }
      });
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setOpenActionMenu(true);
  };

  const handleSetPrimaryImage = () => {
    setSelectedDataObj((prevState) => {
      return {
        ...prevState,
        images: prevState.images.map((image, index) => ({
          ...image,
          isPrimary: index === selectedImageIndex,
        })),
      };
    });
    setOpenActionMenu(false);
  };

  const handleImageStatus = (status = true) => {
    setSelectedDataObj((prevState) => {
      return {
        ...prevState,
        images: prevState.images.map((image, index) => (index === selectedImageIndex ? { ...image, isActive: status } : image)),
      };
    });
    setOpenActionMenu(false);
  };

  return (
    <Card sx={{ p: 3 }} style={{ height: '100%' }}>
      <Typography variant='subtitle1' padding='10px 0'>
        Image Uploader
      </Typography>
      <Button variant='contained' onClick={handleImageUpload}>
        Upload Images
      </Button>

      <ImageList variant='masonry' cols={5} gap={8} sx={{ mt: 2 }}>
        {images?.map((image, index) => (
          <ImageListItem key={index} onClick={() => handleImageClick(index)}>
            <img
              srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${image.url}?w=248&fit=crop&auto=format`}
              alt={`Product Image ${index + 1}`}
              loading='lazy'
              style={{
                borderRadius: '4px',
                border: image.isPrimary ? '3px solid gold' : 'none',
                filter: image.isActive ? 'none' : 'grayscale(100%)',
                opacity: image.isActive ? 1 : 0.5,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      {images && images.length > 0 && (
        <Dialog open={openActionMenu} onClose={() => setOpenActionMenu(false)}>
          <DialogTitle>Manage Image</DialogTitle>
          <DialogActions>
            <Button onClick={handleSetPrimaryImage} disabled={images[selectedImageIndex]?.isPrimary || !images[selectedImageIndex]?.isActive}>
              Set as Primary
            </Button>
            <>
              {images[selectedImageIndex]?.isActive ? (
                <Button onClick={() => handleImageStatus(false)} disabled={images[selectedImageIndex]?.isPrimary}>
                  Delete Image
                </Button>
              ) : (
                <Button onClick={() => handleImageStatus(true)}>Restore Image</Button>
              )}
            </>
            <Button onClick={() => setOpenActionMenu(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
}
